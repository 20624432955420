import { ChevronDown, Menu, X } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Globe } from "lucide-react";
import Logo from "../Logo";

const Navbar = ({ className }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Previeni lo scroll quando il menu è aperto
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className={`px-4 py-4 relative ${className}`}>
      {/* Overlay scuro quando il menu è aperto */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-40 md:hidden"
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      <div className="container mx-auto flex items-center justify-between relative z-50">
        <div className="flex items-center gap-8">
          {/* Menu Mobile Toggle - Con margine negativo */}
          <button
            className="md:hidden z-50 -ml-2"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>

          <Link to="/" className="flex items-center gap-2">
            <Logo size={24} />
            <span className="font-bold text-xl text-primary">GuestSuite</span>
          </Link>

          {/* Menu Desktop */}
          <div className="hidden md:flex items-center gap-6">
            {/* Funzionalità Dropdown */}
            <div className="relative group">
              <button className="flex items-center gap-1 text-gray-600 hover:text-primary">
                {t("features_title")}
                <ChevronDown className="h-4 w-4" />
              </button>
              <div className="absolute left-0 top-full mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
                <div className="p-4">
                  <div className="space-y-2">
                    <Link
                      to="/features/online-check-in"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Check-in Online
                    </Link>
                    <Link
                      to="/features/onsite-check-in"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Onsite Check-in
                    </Link>
                    <Link
                      to="/features/identity-verification"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      {t("features_identity_verification_title")}
                    </Link>
                    <Link
                      to="/features/guest-registration"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Registrazione degli ospiti
                    </Link>
                    <Link
                      to="/features/self-check-in"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Self Check-in
                    </Link>
                    <Link
                      to="/features/digital-locks"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Serrature Digitali
                    </Link>
                    <Link
                      to="/features/insurance-deposits"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Assicurazioni e depositi
                    </Link>
                    <Link
                      to="/features/tourist-tax"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Tassa di soggiorno
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Altri menu items */}
            <Link
              to="/integrations"
              className="text-gray-600 hover:text-primary"
            >
              Integrazioni
            </Link>

            {/* Risorse Dropdown */}
            <div className="relative group">
              <button className="flex items-center gap-1 text-gray-600 hover:text-primary">
                Risorse
                <ChevronDown className="h-4 w-4" />
              </button>
              <div className="absolute left-0 top-full mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-100 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
                <div className="p-4">
                  <h3 className="font-semibold text-gray-900 mb-3">
                    Monetizza
                  </h3>
                  <div className="space-y-2">
                    <Link
                      to="/resources/experiences"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Esperienze
                    </Link>
                    <Link
                      to="/resources/payments"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Pagamenti
                    </Link>
                    <Link
                      to="/resources/guest-app"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Guest App personalizzabile
                    </Link>
                    <Link
                      to="/resources/digital-guide"
                      className="block text-left w-full text-gray-600 hover:text-primary"
                    >
                      Guida Digitale
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Link to="/#pricing" className="text-gray-600 hover:text-primary">
              Prezzo
            </Link>
          </div>
        </div>

        {/* Login e CTA buttons - Desktop */}
        <div className="hidden md:flex items-center gap-4">
          {/* Language Selector */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <Globe className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => changeLanguage("it")}>
                🇮🇹 {t("language_it")}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => changeLanguage("en")}>
                🇬🇧 {t("language_en")}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => changeLanguage("es")}>
                🇪🇸 {t("language_es")}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => changeLanguage("fr")}>
                🇫🇷 {t("language_fr")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <Link
            to="/dashboard"
            className="text-gray-600 hover:text-primary hidden md:block"
          >
            {t("menu_login")}
          </Link>
        </div>
      </div>

      {/* Menu Mobile - Slide da sinistra */}
      <div
        className={`
          fixed top-0 left-0 bottom-0 w-[280px] bg-white shadow-xl 
          transform transition-transform duration-300 ease-in-out z-50
          md:hidden
          ${isMenuOpen ? "translate-x-0" : "-translate-x-full"}
        `}
      >
        <div className="h-full overflow-y-auto">
          <div className="p-6 space-y-6">
            {/* Logo section */}
            <div className="flex items-center justify-between">
              <Link
                to="/"
                className="flex items-center gap-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <span className="font-bold text-xl text-primary">
                  GuestSuite
                </span>
              </Link>
            </div>

            {/* Features section */}
            <div className="space-y-2">
              <h3 className="font-semibold text-sm text-gray-500 uppercase tracking-wider">
                {t("features_title")}
              </h3>
              <div className="space-y-3">
                <Link
                  to="/features/online-check-in"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("features_online_checkin")}
                </Link>
                <Link
                  to="/features/onsite-check-in"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("features_onsite_checkin")}
                </Link>
                <Link
                  to="/features/identity-verification"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {t("features_identity_verification_title")}
                </Link>
                <Link
                  to="/features/guest-registration"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Registrazione degli ospiti
                </Link>
                <Link
                  to="/features/self-check-in"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Self Check-in
                </Link>
                <Link
                  to="/features/digital-locks"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Serrature Digitali
                </Link>
                <Link
                  to="/features/insurance-deposits"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Assicurazioni e depositi
                </Link>
                <Link
                  to="/features/tourist-tax"
                  className="block text-gray-600 hover:text-primary"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Tassa di soggiorno
                </Link>
              </div>
            </div>

            {/* Pricing section */}
            <div className="space-y-2">
              <Link
                to="/pricing"
                className="block text-gray-600 hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("features_pricing")}
              </Link>
            </div>

            {/* Language Selector */}
            <div className="space-y-2">
              <div className="grid grid-cols-2 gap-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <Globe className="h-5 w-5" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => changeLanguage("it")}>
                      🇮🇹 {t("language_it")}
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => changeLanguage("en")}>
                      🇬🇧 {t("language_en")}
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => changeLanguage("es")}>
                      🇪🇸 {t("language_es")}
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => changeLanguage("fr")}>
                      🇫🇷 {t("language_fr")}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>

            {/* Login/Signup section */}
            <div className="pt-4 border-t space-y-3">
              <Link
                to="/login"
                className="block text-center text-gray-600 hover:text-primary"
                onClick={() => setIsMenuOpen(false)}
              >
                {t("menu_login")}
              </Link>
              <Button
                onClick={() => {
                  navigate("/signup");
                  setIsMenuOpen(false);
                }}
                className="w-full bg-primary text-white hover:bg-primary/90"
              >
                {t("features_try_free")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
