import { supabase } from "../lib/supabase";

/**
 * Servizio per la gestione degli appartamenti con Supabase
 */
export const apartmentService = {
  /**
   * Ottiene tutti gli appartamenti dell'utente corrente
   */
  getUserApartments: async () => {
    try {
      const { data, error } = await supabase
        .from("apartments")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error("Error fetching user apartments:", error);
      return { data: null, error };
    }
  },

  /**
   * Ottiene un appartamento specifico per ID
   */
  getApartmentById: async (apartmentId) => {
    try {
      const { data, error } = await supabase
        .from("apartments")
        .select("*")
        .eq("id", apartmentId)
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(`Error fetching apartment with ID ${apartmentId}:`, error);
      return { data: null, error };
    }
  },

  /**
   * Crea un nuovo appartamento
   */
  createApartment: async (apartmentData) => {
    try {
      // Ottieni l'utente corrente
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) throw new Error("User not authenticated");

      // Aggiungi l'ID utente ai dati dell'appartamento
      const newApartment = {
        ...apartmentData,
        user_id: user.id,
        created_at: new Date(),
        updated_at: new Date(),
      };

      const { data, error } = await supabase
        .from("apartments")
        .insert([newApartment])
        .select();

      if (error) throw error;

      return { data: data[0], error: null };
    } catch (error) {
      console.error("Error creating apartment:", error);
      return { data: null, error };
    }
  },

  /**
   * Aggiorna un appartamento esistente
   */
  updateApartment: async (apartmentId, apartmentData) => {
    try {
      const updates = {
        ...apartmentData,
        updated_at: new Date(),
      };

      const { data, error } = await supabase
        .from("apartments")
        .update(updates)
        .eq("id", apartmentId)
        .select();

      if (error) throw error;

      return { data: data[0], error: null };
    } catch (error) {
      console.error(`Error updating apartment with ID ${apartmentId}:`, error);
      return { data: null, error };
    }
  },

  /**
   * Elimina un appartamento
   */
  deleteApartment: async (apartmentId) => {
    try {
      const { error } = await supabase
        .from("apartments")
        .delete()
        .eq("id", apartmentId);

      if (error) throw error;

      return { success: true, error: null };
    } catch (error) {
      console.error(`Error deleting apartment with ID ${apartmentId}:`, error);
      return { success: false, error };
    }
  },
};
