import { createClient } from "@supabase/supabase-js";

// Usa il prefisso REACT_APP_ per Create React App
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  console.error(
    "Mancano le variabili di ambiente per Supabase. Assicurati di aver configurato REACT_APP_SUPABASE_URL e REACT_APP_SUPABASE_ANON_KEY nel file .env",
  );
}

// Crea un client temporaneo per evitare errori durante lo sviluppo
// Questo verrà sostituito con il client reale quando le variabili d'ambiente saranno configurate
export const supabase =
  supabaseUrl && supabaseKey
    ? createClient(supabaseUrl, supabaseKey)
    : {
        auth: {
          signInWithPassword: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
          signInWithOAuth: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
          signInWithOtp: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
          signUp: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
          signOut: () => Promise.resolve({ error: null }),
          getUser: () => Promise.resolve({ data: { user: null }, error: null }),
          getSession: () =>
            Promise.resolve({ data: { session: null }, error: null }),
          onAuthStateChange: () => ({
            data: { subscription: { unsubscribe: () => {} } },
            error: null,
          }),
        },
        from: () => ({
          select: () =>
            Promise.resolve({
              data: [],
              error: new Error("Supabase non configurato"),
            }),
          insert: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
          update: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
          delete: () =>
            Promise.resolve({
              data: null,
              error: new Error("Supabase non configurato"),
            }),
        }),
      };
