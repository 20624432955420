import { supabase } from "../lib/supabase";

export const helpArticleService = {
  /**
   * Ottiene tutti gli articoli di aiuto
   */
  getAllHelpArticles: async () => {
    try {
      const { data, error } = await supabase
        .from("help_articles")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error("Error fetching help articles:", error);
      return { data: null, error };
    }
  },

  /**
   * Ottiene gli articoli di aiuto per categoria
   */
  getHelpArticlesByCategory: async (category) => {
    try {
      const { data, error } = await supabase
        .from("help_articles")
        .select("*")
        .eq("category", category)
        .order("created_at", { ascending: false });

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(
        `Error fetching help articles for category ${category}:`,
        error,
      );
      return { data: null, error };
    }
  },

  /**
   * Ottiene un articolo di aiuto specifico per ID
   */
  getHelpArticleById: async (articleId) => {
    try {
      const { data, error } = await supabase
        .from("help_articles")
        .select("*")
        .eq("id", articleId)
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(`Error fetching help article with ID ${articleId}:`, error);
      return { data: null, error };
    }
  },

  /**
   * Crea un nuovo articolo di aiuto (solo per admin)
   */
  createHelpArticle: async (articleData) => {
    try {
      // Ottieni l'utente corrente
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) throw new Error("User not authenticated");

      // Aggiungi l'ID utente ai dati dell'articolo
      const newArticle = {
        ...articleData,
        user_id: user.id,
        created_at: new Date(),
        updated_at: new Date(),
      };

      const { data, error } = await supabase
        .from("help_articles")
        .insert([newArticle])
        .select()
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error("Error creating help article:", error);
      return { data: null, error };
    }
  },

  /**
   * Aggiorna un articolo di aiuto esistente (solo per admin)
   */
  updateHelpArticle: async (articleId, articleData) => {
    try {
      const updates = {
        ...articleData,
        updated_at: new Date(),
      };

      const { data, error } = await supabase
        .from("help_articles")
        .update(updates)
        .eq("id", articleId)
        .select()
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(`Error updating help article with ID ${articleId}:`, error);
      return { data: null, error };
    }
  },

  /**
   * Elimina un articolo di aiuto (solo per admin)
   */
  deleteHelpArticle: async (articleId) => {
    try {
      const { error } = await supabase
        .from("help_articles")
        .delete()
        .eq("id", articleId);

      if (error) throw error;

      return { success: true, error: null };
    } catch (error) {
      console.error(`Error deleting help article with ID ${articleId}:`, error);
      return { success: false, error };
    }
  },
};
