import { supabase } from "../lib/supabase";

export const guideService = {
  /**
   * Ottiene tutte le guide dell'utente corrente
   */
  getUserGuides: async () => {
    try {
      const { data, error } = await supabase
        .from("guides")
        .select("*, apartments(id, title)")
        .order("created_at", { ascending: false });

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error("Error fetching user guides:", error);
      return { data: null, error };
    }
  },

  /**
   * Ottiene una guida specifica per ID
   */
  getGuideById: async (guideId) => {
    try {
      const { data, error } = await supabase
        .from("guides")
        .select("*, apartments(id, title)")
        .eq("id", guideId)
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(`Error fetching guide with ID ${guideId}:`, error);
      return { data: null, error };
    }
  },

  /**
   * Crea una nuova guida
   */
  createGuide: async (guideData) => {
    try {
      // Ottieni l'utente corrente
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) throw new Error("User not authenticated");

      // Aggiungi l'ID utente ai dati della guida
      const newGuide = {
        ...guideData,
        user_id: user.id,
        created_at: new Date(),
        updated_at: new Date(),
      };

      const { data, error } = await supabase
        .from("guides")
        .insert([newGuide])
        .select()
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error("Error creating guide:", error);
      return { data: null, error };
    }
  },

  /**
   * Aggiorna una guida esistente
   */
  updateGuide: async (guideId, guideData) => {
    try {
      const updates = {
        ...guideData,
        updated_at: new Date(),
      };

      const { data, error } = await supabase
        .from("guides")
        .update(updates)
        .eq("id", guideId)
        .select()
        .single();

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(`Error updating guide with ID ${guideId}:`, error);
      return { data: null, error };
    }
  },

  /**
   * Elimina una guida
   */
  deleteGuide: async (guideId) => {
    try {
      const { error } = await supabase
        .from("guides")
        .delete()
        .eq("id", guideId);

      if (error) throw error;

      return { success: true, error: null };
    } catch (error) {
      console.error(`Error deleting guide with ID ${guideId}:`, error);
      return { success: false, error };
    }
  },

  /**
   * Carica un'immagine per una guida
   */
  uploadGuideImage: async (file) => {
    try {
      // Ottieni l'utente corrente
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) throw new Error("User not authenticated");

      // Validazione del file
      if (!file || !(file instanceof File)) {
        throw new Error("Invalid file");
      }

      // Verifica il tipo di file
      const fileExt = file.name.split(".").pop();
      const allowedTypes = ["jpg", "jpeg", "png", "gif"];

      if (!allowedTypes.includes(fileExt.toLowerCase())) {
        throw new Error(
          "Invalid file type. Only JPG, PNG and GIF are allowed.",
        );
      }

      // Verifica la dimensione del file (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error("File too large. Maximum size is 5MB.");
      }

      // Genera un nome file unico
      const fileName = `${user.id}/${Math.random().toString(36).substring(2, 15)}_${Date.now()}.${fileExt}`;

      // Carica il file
      const { data, error } = await supabase.storage
        .from("guides")
        .upload(fileName, file);

      if (error) throw error;

      // Ottieni l'URL pubblico
      const {
        data: { publicUrl },
      } = supabase.storage.from("guides").getPublicUrl(fileName);

      return {
        url: publicUrl,
        name: fileName,
        error: null,
      };
    } catch (error) {
      console.error("Error uploading guide image:", error);
      return { url: null, name: null, error };
    }
  },
};
