import React from "react";
import { ArrowRight } from "lucide-react";
import { Button } from "../../components/ui/button";

const IntegrationsSection = () => {
  // Array di integrazioni disponibili
  const integrations = [
    {
      name: "Airbnb",
      logo: "/logos/airbnb.svg",
      description: "Sincronizza calendari e prenotazioni",
      color: "bg-[#FF5A5F]/10",
    },
    {
      name: "Booking.com",
      logo: "/logos/booking.svg",
      description: "Gestione automatica delle prenotazioni",
      color: "bg-[#003580]/10",
    },
    {
      name: "Stripe",
      logo: "/logos/stripe.svg",
      description: "Pagamenti sicuri e gestione fatturazione",
      color: "bg-[#635BFF]/10",
    },
    {
      name: "Google Calendar",
      logo: "/logos/google-calendar.svg",
      description: "Sincronizzazione eventi e disponibilità",
      color: "bg-[#4285F4]/10",
    },
    {
      name: "iCal",
      logo: "/logos/ical.svg",
      description: "Compatibile con tutti i calendari",
      color: "bg-[#0080FF]/10",
    },
    {
      name: "Alloggiati Web",
      logo: "/logos/polizia.svg",
      description: "Invio automatico dati alla Polizia di Stato",
      color: "bg-[#003366]/10",
    },
    {
      name: "ISTAT",
      logo: "/logos/istat.svg",
      description: "Generazione report statistici",
      color: "bg-[#00843D]/10",
    },
    {
      name: "Nuki",
      logo: "/logos/nuki.svg",
      description: "Serrature smart e accessi digitali",
      color: "bg-[#31AF91]/10",
    },
  ];

  return (
    <section id="integrations" className="py-20 bg-gray-50 scroll-mt-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Integrazioni Potenti
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Connetti GuestSuite con i tuoi strumenti preferiti per un'esperienza
            senza interruzioni
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto">
          {integrations.map((integration, index) => (
            <div
              key={index}
              className={`rounded-lg p-6 border border-gray-200 hover:border-primary/30 hover:shadow-md transition-all ${integration.color}`}
            >
              <div className="h-12 w-12 mb-4 flex items-center justify-center bg-white rounded-md shadow-sm">
                <img
                  src={integration.logo}
                  alt={integration.name}
                  className="h-8 w-8 object-contain"
                />
              </div>
              <h3 className="text-lg font-semibold mb-2">{integration.name}</h3>
              <p className="text-gray-600 text-sm">{integration.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <Button
            variant="outline"
            className="border-primary text-primary hover:bg-primary hover:text-white"
          >
            Scopri tutte le integrazioni
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default IntegrationsSection;
