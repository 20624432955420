import { supabase } from "../lib/supabase";
import { showToast } from "../utils/toast";

export const reservationService = {
  /**
   * Ottiene tutte le prenotazioni dell'utente corrente
   */
  getUserReservations: async () => {
    try {
      // Ottieni la sessione corrente
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();

      if (sessionError) {
        console.log("Errore di sessione:", sessionError.message);
        return []; // Ritorna array vuoto invece di lanciare un errore
      }

      // Verifica che ci sia una sessione e un utente
      if (!sessionData?.session?.user?.id) {
        console.log("Nessun utente autenticato trovato nella sessione");
        return []; // Ritorna array vuoto
      }

      const userId = sessionData.session.user.id;
      console.log("Fetching reservations for user ID:", userId);

      // Esegui la query con l'ID utente dalla sessione
      const { data: reservationsData, error: reservationsError } =
        await supabase
          .from("reservations")
          .select("*")
          .eq("created_by", userId);

      if (reservationsError) {
        console.error(
          "Errore nel recupero delle prenotazioni:",
          reservationsError.message,
        );
        return []; // Ritorna array vuoto invece di lanciare un errore
      }

      // Se non ci sono dati, ritorna un array vuoto
      return reservationsData || [];
    } catch (error) {
      console.error("Error in getUserReservations:", error);
      return []; // Ritorna array vuoto in caso di qualsiasi errore
    }
  },

  /**
   * Ottiene le prenotazioni per un appartamento specifico
   */
  getReservationsByApartment: async (apartmentId) => {
    try {
      const { data, error } = await supabase
        .from("reservations")
        .select("*")
        .eq("apartment_id", apartmentId)
        .order("check_in", { ascending: true });

      if (error) throw error;

      return { data, error: null };
    } catch (error) {
      console.error(
        `Error fetching reservations for apartment ${apartmentId}:`,
        error,
      );
      return { data: null, error };
    }
  },

  /**
   * Ottiene una prenotazione specifica per ID
   */
  getReservationById: async (reservationId) => {
    try {
      const { data, error } = await supabase
        .from("reservations")
        .select(
          `
          *,
          guests (*)
        `,
        )
        .eq("id", reservationId)
        .single();

      if (error) throw error;

      // Get apartment details
      const { data: apartment, error: apartmentError } = await supabase
        .from("apartments")
        .select("name, title")
        .eq("id", data.apartment_id)
        .single();

      if (apartmentError) throw apartmentError;

      return {
        ...data,
        apartment_title:
          apartment?.title || apartment?.name || "Unknown Apartment",
      };
    } catch (error) {
      console.error("Error in getReservationById:", error);
      throw error;
    }
  },

  /**
   * Crea una nuova prenotazione
   */
  createReservation: async (reservationData) => {
    try {
      // Get the current user
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) {
        throw new Error("User not authenticated");
      }

      // Add created_by to the reservation data
      const newReservation = {
        ...reservationData,
        created_by: user.id,
      };

      const { data, error } = await supabase
        .from("reservations")
        .insert(newReservation)
        .select()
        .single();

      if (error) throw error;

      // Create a main guest if number_of_guests > 0
      if (data && data.number_of_guests > 0) {
        const mainGuest = {
          reservation_id: data.id,
          name: "",
          surname: "",
          guest_type: "main",
          police_status: false,
          istat_checkin: false,
          istat_checkout: false,
        };

        const { error: guestError } = await supabase
          .from("guests")
          .insert(mainGuest);

        if (guestError) throw guestError;
      }

      return data;
    } catch (error) {
      console.error("Error in createReservation:", error);
      throw error;
    }
  },

  /**
   * Aggiorna una prenotazione esistente
   */
  updateReservation: async (reservationId, reservationData) => {
    try {
      const { data, error } = await supabase
        .from("reservations")
        .update(reservationData)
        .eq("id", reservationId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error in updateReservation:", error);
      throw error;
    }
  },

  /**
   * Elimina una prenotazione
   */
  deleteReservation: async (reservationId) => {
    try {
      // Delete all guests first (should happen automatically with CASCADE)
      const { error: guestsError } = await supabase
        .from("guests")
        .delete()
        .eq("reservation_id", reservationId);

      if (guestsError) throw guestsError;

      // Then delete the reservation
      const { error } = await supabase
        .from("reservations")
        .delete()
        .eq("id", reservationId);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error("Error in deleteReservation:", error);
      throw error;
    }
  },

  /**
   * Verifica la disponibilità di un appartamento per un periodo specifico
   */
  checkAvailability: async (apartmentId, checkIn, checkOut) => {
    try {
      const { data, error } = await supabase
        .from("reservations")
        .select("*")
        .eq("apartment_id", apartmentId)
        .or(`check_in.lte.${checkOut},check_out.gte.${checkIn}`);

      if (error) throw error;

      // Se non ci sono prenotazioni che si sovrappongono, l'appartamento è disponibile
      const isAvailable = data.length === 0;

      return { isAvailable, conflictingReservations: data, error: null };
    } catch (error) {
      console.error(
        `Error checking availability for apartment ${apartmentId}:`,
        error,
      );
      return { isAvailable: false, conflictingReservations: [], error };
    }
  },

  // Gestione degli ospiti
  createGuest: async (guestData) => {
    try {
      const { data, error } = await supabase
        .from("guests")
        .insert(guestData)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error in createGuest:", error);
      throw error;
    }
  },

  updateGuest: async (id, guestData) => {
    try {
      const { data, error } = await supabase
        .from("guests")
        .update(guestData)
        .eq("id", id)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error in updateGuest:", error);
      throw error;
    }
  },

  deleteGuest: async (id) => {
    try {
      const { error } = await supabase.from("guests").delete().eq("id", id);

      if (error) throw error;
      return true;
    } catch (error) {
      console.error("Error in deleteGuest:", error);
      throw error;
    }
  },
};
