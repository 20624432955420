import { Plus, Upload, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../../lib/supabase";
import { languages, services } from "../../utils/constants";
import { showToast } from "../../utils/toast";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { DialogFooter } from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { ScrollArea } from "../ui/scroll-area";
import { Textarea } from "../ui/textarea";

const ItemForm = ({ editingItem, onSubmit, onCancel, itemType }) => {
  const [translations, setTranslations] = useState({
    it: { title: "", description: "" },
    en: { title: "", description: "" },
    fr: { title: "", description: "" },
    de: { title: "", description: "" },
    es: { title: "", description: "" },
  });
  const [selectedServices, setSelectedServices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [icalUrls, setIcalUrls] = useState([]);
  const [maxGuests, setMaxGuests] = useState(1);
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("it");
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editingItem) {
      console.log(editingItem);
      setTranslations(
        editingItem.translations || {
          it: { title: "", description: "" },
          en: { title: "", description: "" },
          fr: { title: "", description: "" },
          de: { title: "", description: "" },
          es: { title: "", description: "" },
        },
      );
      setSelectedServices(editingItem.services || []);
      setPhotos(
        editingItem.photos?.map((photo) => ({
          ...photo,
          isExisting: true,
          url: photo.url || (typeof photo === "string" ? photo : ""),
        })) || [],
      );
      setIcalUrls(
        Array.isArray(editingItem.ical_urls) ? editingItem.ical_urls : [],
      );
      setMaxGuests(editingItem.max_guests || 1);
      setLocation(editingItem.location || "");
      setAddress(editingItem.address || "");
    }
  }, [editingItem]);

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const handleServiceToggle = (serviceId) => {
    setSelectedServices((prev) =>
      prev.includes(serviceId)
        ? prev.filter((id) => id !== serviceId)
        : [...prev, serviceId],
    );
  };

  const handlePhotoUpload = (e) => {
    const files = e.target.files;
    if (files) {
      const newPhotos = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        isNew: true,
        name: file.name,
      }));
      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    }
  };

  const handleRemovePhoto = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  const handleAddIcalUrl = () => {
    if (icalUrls.length < 4) {
      setIcalUrls((prev) => [...prev, ""]);
    }
  };

  const handleRemoveIcalUrl = (index) => {
    setIcalUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const handleIcalUrlChange = (index, value) => {
    setIcalUrls((prev) => {
      const newUrls = [...prev];
      newUrls[index] = value;
      return newUrls;
    });
  };

  const uploadPhotosToSupabase = async (photos) => {
    const uploadedPhotos = [];

    for (const photo of photos.filter((p) => p.isNew && p.file)) {
      const fileExt = photo.file.name.split(".").pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}_${Date.now()}.${fileExt}`;
      const filePath = `${itemType}/${fileName}`;

      const { data, error } = await supabase.storage
        .from(itemType)
        .upload(filePath, photo.file);

      if (error) {
        console.error("Error uploading file:", error);
        throw new Error(`Error uploading file: ${error.message}`);
      }

      const {
        data: { publicUrl },
      } = supabase.storage.from(itemType).getPublicUrl(filePath);

      uploadedPhotos.push({
        name: fileName,
        url: publicUrl,
      });
    }

    return uploadedPhotos;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Verifica che almeno un titolo (IT o EN) sia presente
      const hasItalianTitle =
        translations.it?.title && translations.it.title.trim() !== "";
      const hasEnglishTitle =
        translations.en?.title && translations.en.title.trim() !== "";

      if (!hasItalianTitle && !hasEnglishTitle) {
        throw new Error(
          "È necessario inserire almeno un titolo in italiano o in inglese",
        );
      }

      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error("User not authenticated");

      let allPhotos = [...photos.filter((p) => p.isExisting)];

      if (photos.some((p) => p.isNew)) {
        const uploadedPhotos = await uploadPhotosToSupabase(photos);
        allPhotos = [...allPhotos, ...uploadedPhotos];
      }

      // Determina il titolo principale da utilizzare
      // Priorità: 1. Italiano, 2. Inglese, 3. Prima lingua non vuota
      let title = "";
      if (hasItalianTitle) {
        title = translations.it.title;
      } else if (hasEnglishTitle) {
        title = translations.en.title;
      } else {
        // Cerca il primo titolo non vuoto in qualsiasi lingua
        for (const lang of languages) {
          if (
            translations[lang]?.title &&
            translations[lang].title.trim() !== ""
          ) {
            title = translations[lang].title;
            break;
          }
        }
      }

      const itemData = {
        user_id: user.id,
        translations,
        services: selectedServices,
        photos: allPhotos,
        updated_at: new Date(),
        title: title,
      };

      if (itemType === "apartments") {
        itemData.ical_urls = icalUrls;
        itemData.max_guests = parseInt(maxGuests, 10);
        itemData.location = location || "";
        itemData.address = address || "";
      }

      let result;
      const isEditing = editingItem && editingItem.id;

      if (isEditing) {
        const { data, error } = await supabase
          .from(itemType)
          .update(itemData)
          .eq("id", editingItem.id)
          .select()
          .single();

        if (error) throw error;
        result = data;
        showToast.success(`${itemType} modificato con successo`);
      } else {
        itemData.created_at = new Date();

        const { data, error } = await supabase
          .from(itemType)
          .insert([itemData])
          .select()
          .single();

        if (error) throw error;
        result = data;
        showToast.success(`${itemType} creato con successo`);
      }

      onSubmit(result);
    } catch (error) {
      console.error(`Error saving ${itemType}:`, error);
      showToast.error(
        error.message || `Errore durante il salvataggio di ${itemType}`,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex border-b border-gray-200">
        {languages.map((lang) => (
          <button
            key={lang}
            type="button"
            className={`px-3 py-1 text-sm ${
              activeTab === lang
                ? "bg-white border-t border-x border-gray-200 -mb-px"
                : "bg-gray-100 text-gray-600"
            }`}
            onClick={() => setActiveTab(lang)}
          >
            {lang.toUpperCase()}
          </button>
        ))}
      </div>

      <div className="space-y-3">
        <div>
          <Label htmlFor={`title-${activeTab}`} className="text-sm mb-1">
            {t("item_form_title_label")} ({activeTab.toUpperCase()})
          </Label>
          <Input
            id={`title-${activeTab}`}
            value={translations[activeTab]?.title || ""}
            onChange={(e) =>
              handleTranslationChange(activeTab, "title", e.target.value)
            }
            placeholder={t("item_form_title_placeholder")}
            required={activeTab === "it"}
            className="h-8"
          />
        </div>

        <div>
          <Label htmlFor={`description-${activeTab}`} className="text-sm mb-1">
            {t("item_form_description_label")} ({activeTab.toUpperCase()})
          </Label>
          <Textarea
            id={`description-${activeTab}`}
            value={translations[activeTab]?.description || ""}
            onChange={(e) =>
              handleTranslationChange(activeTab, "description", e.target.value)
            }
            placeholder={t("item_form_description_placeholder")}
            required={activeTab === "it"}
            className="min-h-[80px] px-2 py-1 text-sm"
          />
        </div>
      </div>

      {itemType === "apartments" && (
        <div className="space-y-3">
          <div>
            <Label htmlFor="address" className="text-sm mb-1">
              {t("item_form_address_label")}
            </Label>
            <Input
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={t("item_form_address_placeholder")}
              className="h-8"
            />
          </div>

          <div>
            <Label htmlFor="maxGuests" className="text-sm mb-1">
              {t("item_form_max_guests_label")}
            </Label>
            <Input
              id="maxGuests"
              type="number"
              min="1"
              value={maxGuests}
              onChange={(e) => setMaxGuests(e.target.value)}
              className="h-8 w-24"
            />
          </div>
        </div>
      )}

      <div className="space-y-2">
        <Label className="text-sm">{t("item_form_services_label")}</Label>
        <div className="grid grid-cols-3 gap-1 text-sm">
          {services.map((service) => (
            <div key={service.id} className="flex items-center space-x-1">
              <Checkbox
                id={service.id}
                checked={selectedServices.includes(service.id)}
                onCheckedChange={() => handleServiceToggle(service.id)}
                className="h-4 w-4"
              />
              <Label htmlFor={service.id} className="text-sm">
                {service.label}
              </Label>
            </div>
          ))}
        </div>
      </div>

      {itemType === "apartments" && (
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Label className="text-sm">{t("item_form_ical_url_label")}</Label>
            {icalUrls.length < 4 && (
              <Button
                type="button"
                onClick={handleAddIcalUrl}
                variant="outline"
                size="sm"
                className="h-7 flex items-center gap-1"
              >
                {t("item_form_add_ical_url")}
                <Plus className="h-3 w-3" />
              </Button>
            )}
          </div>

          {icalUrls.map((url, index) => (
            <div key={index} className="flex items-center space-x-1">
              <Input
                value={url}
                onChange={(e) => handleIcalUrlChange(index, e.target.value)}
                placeholder={t("item_form_ical_url_placeholder")}
                className="h-8 text-sm"
              />
              <Button
                type="button"
                onClick={() => handleRemoveIcalUrl(index)}
                variant="outline"
                size="sm"
                className="h-8 w-8 p-0 flex items-center justify-center"
              >
                <X className="h-3 w-3" />
              </Button>
            </div>
          ))}
          {icalUrls.length >= 4 && (
            <p className="text-xs text-gray-500 mt-1">
              Hai raggiunto il limite massimo di 4 URL iCal
            </p>
          )}
        </div>
      )}

      <div>
        <Label className="text-sm mb-1">{t("item_form_photos_label")}</Label>
        <div
          className="border border-dashed border-gray-300 rounded-lg p-2 text-center cursor-pointer hover:border-gray-400 transition-colors"
          onClick={() => fileInputRef.current?.click()}
        >
          <Upload className="mx-auto h-5 w-5 text-gray-400 mb-1" />
          <p className="text-xs text-gray-600">{t("item_form_upload_photo")}</p>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            multiple
            accept="image/*"
            onChange={handlePhotoUpload}
          />
        </div>
      </div>

      {photos.length > 0 && (
        <ScrollArea className="h-[120px] w-full rounded-md border p-1">
          <div className="flex flex-wrap gap-1">
            {photos.map((photo, index) => (
              <div key={index} className="relative">
                <img
                  src={photo.preview || photo.url || ""}
                  alt={`Photo ${index + 1}`}
                  className="w-16 h-16 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemovePhoto(index)}
                  className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-0.5"
                >
                  <X className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
        </ScrollArea>
      )}

      <DialogFooter className="flex justify-between gap-2 pt-2">
        <Button
          type="button"
          variant="outline"
          onClick={onCancel}
          className="h-8 px-4 flex items-center justify-center"
        >
          {t("common_cancel")}
        </Button>
        <Button
          type="submit"
          disabled={isSubmitting}
          className="h-8 px-4 flex items-center justify-center"
        >
          {isSubmitting ? t("common_saving") : t("common_save")}
        </Button>
      </DialogFooter>
    </form>
  );
};

export default ItemForm;
