import Navbar from "../marketing/Navbar";
import Footer from "../common/Footer";

const FeatureLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-full break-words">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default FeatureLayout;
