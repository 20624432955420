import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import ReservationDetail from "./ReservationDetail";
import { Trash2, RefreshCw, Calendar as CalendarIcon } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Calendar } from "../ui/calendar";
import { addDays, format } from "date-fns";
import { it } from "date-fns/locale";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";
import { supabase } from "../../lib/supabase";
import { showToast } from "../../utils/toast";
import { useAuth } from "../../contexts/AuthContext";

const Reservations = ({
  reservations: initialReservations,
  apartments,
  onReservationsChange,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [newReservation, setNewReservation] = useState({
    apartment_id: "",
    apartment_title: "",
    check_in_date: "",
    check_out_date: "",
    number_of_guests: 1,
    status: "Pending",
    main_guest_name: "",
    main_guest_surname: "",
    main_guest_telephone: "",
  });
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [localReservations, setLocalReservations] = useState(
    initialReservations || [],
  );
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [date, setDate] = useState({
    from: undefined,
    to: undefined,
  });
  const [timeFilter, setTimeFilter] = useState("future");
  const [syncingApartment, setSyncingApartment] = useState(null);
  const [selectedApartmentId, setSelectedApartmentId] = useState("all");
  const [localApartments, setLocalApartments] = useState(apartments || []);

  useEffect(() => {
    if (date?.from) {
      setNewReservation((prev) => ({
        ...prev,
        check_in_date: format(date.from, "yyyy-MM-dd"),
        check_out_date: date?.to ? format(date.to, "yyyy-MM-dd") : "",
      }));
    }
  }, [date]);

  const fetchReservations = useCallback(async () => {
    console.log("fetchReservations chiamato", new Date().toISOString());
    try {
      setIsLoading(true);
      console.log("Recupero prenotazioni...");

      if (!user || !user.id) {
        console.log(
          "Utente non autenticato, impossibile recuperare prenotazioni",
        );
        setIsLoading(false);
        setLocalReservations([]);
        return [];
      }

      let query = supabase
        .from("reservations")
        .select("*")
        .eq("created_by", user.id);

      if (selectedApartmentId && selectedApartmentId !== "all") {
        query = query.eq("apartment_id", selectedApartmentId);
      }

      const { data: reservationsData, error: reservationsError } = await query;

      if (reservationsError) {
        console.error("Error fetching reservations:", reservationsError);
        setLocalReservations([]);
        return [];
      }

      console.log("Prenotazioni ricevute:", reservationsData || []);

      const sortedReservations = (reservationsData || []).sort(
        (a, b) => new Date(a.check_in_date) - new Date(b.check_in_date),
      );
      setLocalReservations(sortedReservations);
      return sortedReservations;
    } catch (error) {
      console.error("Error fetching reservations:", error);
      setLocalReservations([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [t, user, selectedApartmentId]);

  useEffect(() => {
    if (initialReservations && initialReservations.length > 0) {
      setLocalReservations(initialReservations);
    } else if (user && user.id) {
      fetchReservations();
    }

    if (apartments && apartments.length > 0) {
      setLocalApartments(apartments);
    }
  }, [initialReservations, fetchReservations, apartments, user]);

  const syncIcalForApartment = useCallback(
    async (apartmentId) => {
      try {
        setSyncingApartment(apartmentId);
        
        const { data, error } = await supabase.rpc('sync_bookings_from_ical', {
          apartment_id: apartmentId
        });
        
        if (error) {
          console.error("Error calling sync_bookings_from_ical:", error);
          showToast.error(t("errors.icalSyncFailed"));
          return;
        }
        
        if (data.success) {
          showToast.success(t("success.icalSyncRequestSent"));
          setTimeout(() => {
            fetchReservations();
          }, 3000);
        } else {
          showToast.error(data.error || t("errors.icalSyncFailed"));
        }
      } catch (error) {
        console.error("Error syncing iCal:", error);
        showToast.error(t("errors.icalSyncFailed"));
      } finally {
        setSyncingApartment(null);
      }
    },
    [fetchReservations, t]
  );

  const handleCreateReservation = async (e) => {
    e.preventDefault();
    try {
      const reservationData = {
        apartment_id: parseInt(newReservation.apartment_id),
        apartment_title: newReservation.apartment_title,
        check_in_date: newReservation.check_in_date,
        check_out_date: newReservation.check_out_date,
        number_of_guests: parseInt(newReservation.number_of_guests),
        status: newReservation.status,
        created_by: user.id,
      };

      if (!reservationData.apartment_id) {
        showToast.error(t("please_select_apartment"));
        return;
      }

      if (!reservationData.check_in_date || !reservationData.check_out_date) {
        showToast.error(t("please_select_dates"));
        return;
      }

      const { data, error } = await supabase
        .from("reservations")
        .insert(reservationData)
        .select();

      if (error) {
        throw error;
      }

      console.log("Prenotazione creata:", data);
      showToast.success(
        t("reservation_created_successfully") ||
          "Prenotazione creata con successo",
      );

      await fetchReservations();
      setIsCreatingReservation(false);
      setNewReservation({
        apartment_id: "",
        apartment_title: "",
        check_in_date: "",
        check_out_date: "",
        number_of_guests: 1,
        status: "Pending",
      });
      setDate({ from: undefined, to: undefined });
    } catch (error) {
      console.error("Error creating reservation:", error);
      showToast.error(
        t("error_creating_reservation") ||
          "Errore nella creazione della prenotazione",
      );
    }
  };

  const handleRefreshReservations = async () => {
    setIsLoading(true);
    try {
      console.log("Aggiornamento prenotazioni...");

      if (selectedApartmentId) {
        await syncIcalForApartment(selectedApartmentId);
      } else {
        const { data: apartments, error: apartmentsError } = await supabase
          .from("apartments")
          .select("id, title, ical_urls")
          .eq("created_by", user.id)
          .not("ical_urls", "is", null);

        if (apartmentsError) {
          throw apartmentsError;
        }

        for (const apartment of apartments) {
          if (apartment.ical_urls && apartment.ical_urls.length > 0) {
            await syncIcalForApartment(apartment.id);
          }
        }
      }

      const response = await fetchReservations();
      onReservationsChange?.(response);

      showToast.success("Prenotazioni aggiornate con successo");
    } catch (err) {
      console.error(t("error_refreshing_reservations"), err);
      showToast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateReservation = (updatedReservation) => {
    setSelectedReservation(updatedReservation);
    setLocalReservations((prevReservations) => {
      const updated = prevReservations.map((reservation) =>
        reservation.id === updatedReservation.id
          ? updatedReservation
          : reservation,
      );
      return updated.sort(
        (a, b) => new Date(a.check_in_date) - new Date(b.check_in_date),
      );
    });
  };

  const handleDeleteClick = (e, reservation) => {
    e.stopPropagation();
    setReservationToDelete(reservation);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteReservation = async () => {
    try {
      const { error } = await supabase
        .from("reservations")
        .delete()
        .eq("id", reservationToDelete.id);

      if (error) {
        throw error;
      }

      const updatedReservations = localReservations.filter(
        (res) => res.id !== reservationToDelete.id,
      );
      setLocalReservations(updatedReservations);

      onReservationsChange?.(updatedReservations);

      setIsDeleteDialogOpen(false);
      setReservationToDelete(null);

      showToast.success(
        t("reservation_deleted_successfully") ||
          "Prenotazione eliminata con successo",
      );
    } catch (err) {
      console.error(t("error_deleting_reservation"), err);
      showToast.error(
        t("error_deleting_reservation") ||
          "Errore nell'eliminazione della prenotazione",
      );
    }
  };

  const filteredReservations = localReservations.filter((reservation) => {
    const searchMatch =
      searchTerm.toLowerCase() === "" ||
      reservation.apartment_title
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      reservation.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reservation.guests?.some((g) =>
        `${g.name} ${g.surname}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const checkOutDate = new Date(reservation.check_out_date);

    if (timeFilter === "past") {
      return searchMatch && checkOutDate < today;
    } else if (timeFilter === "future") {
      return searchMatch && checkOutDate >= today;
    }
    return searchMatch;
  });

  const reservationsByApartment = apartments.reduce((acc, apartment) => {
    acc[apartment.id] = {
      apartment,
      reservations: localReservations.filter(
        (r) => r.apartment_id === apartment.id,
      ),
    };
    return acc;
  }, {});

  return (
    <>
      {!selectedReservation ? (
        <div className="space-y-6">
          <div className="flex flex-col sm:flex-row gap-4 sm:justify-between">
            <div className="flex flex-col sm:flex-row gap-2">
              <Button
                onClick={() => setIsCreatingReservation(true)}
                className="bg-primary hover:bg-primary/90 text-white font-medium w-full sm:w-auto"
              >
                {t("create_new_reservation")}
              </Button>

              <Button
                onClick={handleRefreshReservations}
                disabled={isLoading}
                variant="outline"
                className="border-gray-200 w-full sm:w-auto"
              >
                {isLoading
                  ? t("refreshing_reservations")
                  : t("refresh_reservations")}
              </Button>

              <div className="w-full sm:w-auto">
                <Select
                  value={selectedApartmentId}
                  onValueChange={(value) => {
                    setSelectedApartmentId(value);
                    fetchReservations();
                  }}
                >
                  <SelectTrigger className="w-full sm:w-[250px]">
                    <SelectValue
                      placeholder={
                        t("select_apartment") || "Seleziona appartamento"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
                  >
                    <SelectItem value="all">
                      {t("all_apartments") || "Tutti gli appartamenti"}
                    </SelectItem>
                    {localApartments &&
                      localApartments.map((apartment) => (
                        <SelectItem
                          key={apartment.id}
                          value={apartment.id.toString()}
                        >
                          {apartment.title}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <Input
              type="text"
              placeholder={t("search_by_apartment_name_guest_name_or_status")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-1"
            />

            <Select value={timeFilter} onValueChange={setTimeFilter}>
              <SelectTrigger className="w-[200px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent
                position="popper"
                className="w-[--radix-select-trigger-width] bg-white dark:bg-gray-800"
              >
                <SelectItem value="all">{t("all_reservations")}</SelectItem>
                <SelectItem value="future">
                  {t("future_reservations")}
                </SelectItem>
                <SelectItem value="past">{t("past_reservations")}</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center h-[calc(100vh-200px)]">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
            </div>
          ) : filteredReservations.length === 0 ? (
            <div className="bg-white rounded-lg border border-gray-200 p-8 text-center">
              <p className="text-gray-500">
                {t("no_reservations_found") || "Nessuna prenotazione trovata"}
              </p>
              <p className="text-sm text-gray-400 mt-2">
                {t("create_reservation_or_change_filter") ||
                  "Crea una nuova prenotazione o modifica i filtri di ricerca"}
              </p>
            </div>
          ) : (
            <>
              <div className="hidden md:block bg-white rounded-lg border border-gray-200 overflow-hidden">
                <div className="overflow-x-auto">
                  <Table className="min-w-full">
                    <TableHeader>
                      <TableRow className="bg-gray-50 border-b border-gray-200">
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("apartment_name")}
                        </TableHead>
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("guest_full_name")}
                        </TableHead>
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("stay_dates")}
                        </TableHead>
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("number_of_guests")}
                        </TableHead>
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("status")}
                        </TableHead>
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("source")}
                        </TableHead>
                        <TableHead className="py-4 px-4 text-xs md:text-sm font-semibold text-gray-900">
                          {t("actions")}
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredReservations.map((reservation, index) => (
                        <TableRow
                          key={reservation.id}
                          className={`
                            cursor-pointer transition-colors
                            hover:bg-gray-50
                            ${index % 2 === 0 ? "bg-white" : "bg-gray-50/50"}
                          `}
                          onClick={() => setSelectedReservation(reservation)}
                        >
                          <TableCell className="py-4 px-4 text-sm text-gray-900">
                            {reservation.apartment_title}
                          </TableCell>
                          <TableCell className="py-4 px-4 text-sm text-gray-900">
                            {
                              reservation.guests?.find(
                                (g) => g.guest_type === "main",
                              )?.name
                            }{" "}
                            {reservation.guests?.find(
                              (g) => g.guest_type === "main",
                            )?.surname || "N/A"}
                          </TableCell>
                          <TableCell className="py-4 px-4 text-sm text-gray-900">
                            <div className="flex items-center space-x-1">
                              <span>
                                {new Date(
                                  reservation.check_in_date,
                                ).toLocaleDateString()}
                              </span>
                              <span className="text-gray-400">→</span>
                              <span>
                                {new Date(
                                  reservation.check_out_date,
                                ).toLocaleDateString()}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell className="py-4 px-4 text-sm text-gray-900">
                            {reservation.guests?.length || 0}
                          </TableCell>
                          <TableCell className="py-4 px-4">
                            <span
                              className={`
                              inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                              ${getStatusColor(reservation.status)}
                            `}
                            >
                              {reservation.status}
                            </span>
                          </TableCell>
                          <TableCell className="py-4 px-4 text-sm text-gray-900">
                            {reservation.source === "ical" ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                iCal
                              </span>
                            ) : (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                {t("direct") || "Diretta"}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="py-4 px-4">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={(e) => handleDeleteClick(e, reservation)}
                              className="text-gray-500 hover:text-red-600 hover:bg-red-50"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>

              <div className="md:hidden space-y-4">
                {filteredReservations.map((reservation) => (
                  <div
                    key={reservation.id}
                    className="bg-white rounded-lg border border-gray-200 p-4 space-y-3 cursor-pointer hover:bg-gray-50"
                    onClick={() => setSelectedReservation(reservation)}
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="font-medium text-gray-900">
                        {reservation.apartment_title}
                      </h3>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={(e) => handleDeleteClick(e, reservation)}
                        className="text-gray-500 hover:text-red-600 hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>

                    <div className="space-y-2">
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {t("guest_full_name")}
                        </span>
                        <span className="text-sm font-medium">
                          {
                            reservation.guests?.find(
                              (g) => g.guest_type === "main",
                            )?.name
                          }{" "}
                          {reservation.guests?.find(
                            (g) => g.guest_type === "main",
                          )?.surname || "N/A"}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {t("stay_dates")}
                        </span>
                        <div className="text-sm font-medium flex items-center gap-1">
                          <span>
                            {new Date(
                              reservation.check_in_date,
                            ).toLocaleDateString()}
                          </span>
                          <span className="text-gray-400">→</span>
                          <span>
                            {new Date(
                              reservation.check_out_date,
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {t("number_of_guests")}
                        </span>
                        <span className="text-sm font-medium">
                          {reservation.guests?.length || 0}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {t("status")}
                        </span>
                        <span
                          className={`
                          inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                          ${getStatusColor(reservation.status)}
                        `}
                        >
                          {reservation.status}
                        </span>
                      </div>

                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {t("source") || "Fonte"}
                        </span>
                        {reservation.source === "ical" ? (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            iCal
                          </span>
                        ) : (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {t("direct") || "Diretta"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <ReservationDetail
            reservation={selectedReservation}
            apartments={apartments}
            onUpdateReservation={handleUpdateReservation}
            onClose={() => setSelectedReservation(null)}
            onBackToReservations={() => setSelectedReservation(null)}
          />
        </>
      )}

      <Dialog
        open={isCreatingReservation}
        onOpenChange={(open) => {
          if (!open) {
            setIsCreatingReservation(false);
            setDate({ from: undefined, to: undefined });
            setNewReservation({
              status: "confirmed",
              apartment_id: "",
              apartment_title: "",
              check_in_date: "",
              check_out_date: "",
              number_of_guests: 1,
            });
          }
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold text-gray-900">
              {t("create_new_reservation")}
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleCreateReservation} className="space-y-6 mt-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-900">
                  {t("apartment_name")}
                </Label>
                <Select
                  value={newReservation.apartment_id || ""}
                  onValueChange={(value) => {
                    const selectedApartment = apartments.find(
                      (apt) => apt.id.toString() === value.toString(),
                    );
                    if (selectedApartment) {
                      setNewReservation((prev) => ({
                        ...prev,
                        apartment_id: selectedApartment.id.toString(),
                        apartment_title: selectedApartment.title,
                      }));
                    }
                  }}
                  required
                >
                  <SelectTrigger className="h-10">
                    <SelectValue placeholder={t("select_apartment")}>
                      {newReservation.apartment_title || t("select_apartment")}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent
                    position="popper"
                    className="w-[--radix-select-trigger-width]"
                  >
                    {apartments.map((apartment) => (
                      <SelectItem
                        key={apartment.id}
                        value={apartment.id.toString()}
                        className="cursor-pointer"
                      >
                        {apartment.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-900">
                  {t("select_dates")}
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="date"
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !date && "text-muted-foreground",
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date?.from ? (
                        date.to ? (
                          <>
                            {format(date.from, "LLL dd, y")} -{" "}
                            {format(date.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(date.from, "LLL dd, y")
                        )
                      ) : (
                        <span>{t("pick_date_range")}</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <div className="bg-white rounded-md border shadow-md p-3">
                      <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                        locale={it}
                        disabled={(date) => date < new Date()}
                        className="bg-white"
                      />
                    </div>
                  </PopoverContent>
                </Popover>
              </div>

              <div className="space-y-2">
                <Label className="text-sm font-medium text-gray-900">
                  {t("number_of_guests")}
                </Label>
                <Input
                  type="number"
                  value={newReservation.number_of_guests}
                  onChange={(e) =>
                    setNewReservation({
                      ...newReservation,
                      number_of_guests: parseInt(e.target.value) || 1,
                    })
                  }
                  min="1"
                  className="h-10"
                  required
                />
              </div>
            </div>

            <DialogFooter className="mt-6 gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setIsCreatingReservation(false)}
                className="w-full md:w-auto"
              >
                {t("cancel")}
              </Button>
              <Button
                type="submit"
                className="w-full md:w-auto bg-primary hover:bg-primary/90"
                disabled={!date?.from || !date?.to}
              >
                {t("create_reservation")}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <AlertDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("confirm_deletion")}</AlertDialogTitle>
            <AlertDialogDescription>
              {t("delete_reservation_confirmation")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t("cancel")}</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteReservation}
              className="bg-red-500 hover:bg-red-600"
            >
              {t("delete")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "confirmed":
      return "bg-green-100 text-green-800";
    case "pending":
      return "bg-yellow-100 text-yellow-800";
    case "cancelled":
      return "bg-red-100 text-red-800";
    case "completed":
      return "bg-blue-100 text-blue-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export default Reservations;
