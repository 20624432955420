import React from "react";

const Footer = () => {
  return (
    <footer className="w-full py-2 text-center text-xs text-gray-400 bg-transparent">
      <div className="mt-4 md:order-1 md:mt-0">
        <p className="text-center text-sm text-gray-500 break-words">
          &copy; {new Date().getFullYear()} Guestsuite Tutti i diritti
          riservati. | Made with{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>{" "}
          by{" "}
          <a
            href="https://simoneruggiero.com"
            target="_blank"
            rel="noopener"
            className="text-primary hover:underline"
          >
            Simone Ruggiero
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
