// client/src/hooks/useApi.js
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { showToast } from "../utils/toast";
import { supabase } from "../lib/supabase";
import { authService } from "../services/authService";

export const useApi = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Per chiamate API al tuo backend (se ancora necessario)
  const apiCall = async (
    method,
    endpoint,
    data = null,
    requiresAuth = true,
    contentType = "application/json",
  ) => {
    setLoading(true);

    try {
      const baseURL = process.env.REACT_APP_BACKEND_URL || "";
      const url = `${baseURL}${endpoint}`;

      console.log(`API Call: ${method.toUpperCase()} ${url}`);

      const headers = {
        "Content-Type": contentType,
      };

      if (requiresAuth) {
        // Ottieni il token da Supabase
        const {
          data: { session },
        } = await supabase.auth.getSession();

        if (!session) {
          console.error("No session found, redirecting to login");
          navigate("/login");
          throw new Error("Sessione scaduta. Effettua nuovamente il login.");
        }

        headers["Authorization"] = `Bearer ${session.access_token}`;
      }

      const config = {
        method,
        url,
        headers,
        data: data,
      };

      console.log("API Call Config:", {
        method,
        url,
        headers: {
          ...headers,
          Authorization: headers.Authorization ? "Bearer [TOKEN]" : undefined,
        },
        data,
      });

      const response = await axios(config);
      console.log(`API Response (${url}):`, response.status);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);

      if (error.response) {
        console.error(
          "API Response Error:",
          error.response.status,
          error.response.data,
        );

        if (error.response.status === 401) {
          await authService.logout();
          navigate("/login");
          showToast.error("Sessione scaduta. Effettua nuovamente il login.");
        }
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Per chiamate dirette a Supabase
  const supabaseCall = async (table, method, params = {}) => {
    setLoading(true);

    try {
      let result;

      switch (method) {
        case "select":
          result = await supabase.from(table).select(params.columns || "*");
          if (params.filter) {
            for (const [key, value] of Object.entries(params.filter)) {
              result = result.eq(key, value);
            }
          }
          if (params.order) {
            result = result.order(params.order.column, {
              ascending: params.order.ascending,
            });
          }
          if (params.limit) {
            result = result.limit(params.limit);
          }
          break;

        case "insert":
          result = await supabase.from(table).insert(params.data).select();
          break;

        case "update":
          result = await supabase.from(table).update(params.data);
          if (params.filter) {
            for (const [key, value] of Object.entries(params.filter)) {
              result = result.eq(key, value);
            }
          }
          if (params.returnData) {
            result = result.select();
          }
          break;

        case "delete":
          result = await supabase.from(table).delete();
          if (params.filter) {
            for (const [key, value] of Object.entries(params.filter)) {
              result = result.eq(key, value);
            }
          }
          break;

        default:
          throw new Error(`Metodo non supportato: ${method}`);
      }

      if (result.error) throw result.error;

      return result.data;
    } catch (error) {
      console.error("Supabase Error:", error);

      if (error.code === "PGRST301") {
        // Errore di autenticazione
        await authService.logout();
        navigate("/login");
        showToast.error("Sessione scaduta. Effettua nuovamente il login.");
      }

      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { apiCall, supabaseCall, loading };
};
