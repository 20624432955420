import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useApartments } from "../../hooks/useApartments";
import { Progress } from "../ui/progress";

const AddApartment = ({ onComplete }) => {
  const { t } = useTranslation();
  const { addApartment, isLoading } = useApartments();
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5;
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    address: "",
    city: "",
    zip_code: "",
    country: "Italia",
    max_guests: 1,
    num_bedrooms: 1,
    num_bathrooms: 1,
    amenities: [],
    photos: [],
  });

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    // Convert numeric inputs to numbers
    if (type === "number") {
      setFormData({ ...formData, [name]: parseInt(value, 10) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addApartment(formData);
      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      console.error("Error adding apartment:", error);
    }
  };

  const renderBasicInfoStep = () => (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {t("apartment_basic_info") || "Informazioni di base"}
        </h2>
        <p className="text-gray-600 mb-6 text-sm">
          {t("apartment_basic_info_desc") ||
            "Inserisci le informazioni principali del tuo appartamento"}
        </p>
      </div>
      <div className="space-y-4">
        <div>
          <Label htmlFor="title" className="text-sm font-medium block mb-1">
            {t("apartment_name") || "Titolo dell'annuncio"}
          </Label>
          <Input
            id="title"
            name="title"
            placeholder={
              t("apartment_name_placeholder") ||
              "Es. Appartamento moderno nel centro di Milano"
            }
            value={formData.title}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <Label
            htmlFor="description"
            className="text-sm font-medium block mb-1"
          >
            {t("apartment_description") || "Descrizione"}
          </Label>
          <Textarea
            id="description"
            name="description"
            placeholder={
              t("apartment_description_placeholder") ||
              "Descrivi il tuo appartamento..."
            }
            value={formData.description}
            onChange={handleInputChange}
            className="w-full min-h-[120px] p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>
    </div>
  );

  const renderLocationStep = () => (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {t("apartment_location") || "Posizione"}
        </h2>
        <p className="text-gray-600 mb-6 text-sm">
          {t("apartment_location_desc") ||
            "Inserisci l'indirizzo dell'appartamento"}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label htmlFor="city" className="text-sm font-medium block mb-1">
            {t("apartment_city") || "Città"}
          </Label>
          <Input
            id="city"
            name="city"
            placeholder={t("apartment_city_placeholder") || "Milano"}
            value={formData.city}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <Label htmlFor="country" className="text-sm font-medium block mb-1">
            {t("apartment_country") || "Paese"}
          </Label>
          <Input
            id="country"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>
      <div>
        <Label htmlFor="address" className="text-sm font-medium block mb-1">
          {t("apartment_address") || "Indirizzo completo"}
        </Label>
        <Input
          id="address"
          name="address"
          placeholder={t("apartment_address_placeholder") || "Via Roma, 123"}
          value={formData.address}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
    </div>
  );

  const renderDetailsStep = () => (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {t("apartment_details") || "Dettagli"}
        </h2>
        <p className="text-gray-600 mb-6 text-sm">
          {t("apartment_details_desc") ||
            "Inserisci i dettagli dell'appartamento"}
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <Label
            htmlFor="max_guests"
            className="text-sm font-medium block mb-1"
          >
            {t("apartment_max_guests") || "Ospiti massimi"}
          </Label>
          <Input
            id="max_guests"
            name="max_guests"
            type="number"
            min="1"
            value={formData.max_guests}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <Label
            htmlFor="num_bedrooms"
            className="text-sm font-medium block mb-1"
          >
            {t("apartment_bedrooms") || "Camere da letto"}
          </Label>
          <Input
            id="num_bedrooms"
            name="num_bedrooms"
            type="number"
            min="1"
            value={formData.num_bedrooms}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <Label
            htmlFor="num_bathrooms"
            className="text-sm font-medium block mb-1"
          >
            {t("apartment_bathrooms") || "Bagni"}
          </Label>
          <Input
            id="num_bathrooms"
            name="num_bathrooms"
            type="number"
            min="1"
            value={formData.num_bathrooms}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>
    </div>
  );

  const amenitiesList = [
    { id: "wifi", label: "WiFi" },
    { id: "ac", label: t("apartment_ac") || "Aria Condizionata" },
    { id: "kitchen", label: t("apartment_kitchen") || "Cucina" },
    { id: "tv", label: t("apartment_tv") || "TV" },
    {
      id: "washing_machine",
      label: t("apartment_washing_machine") || "Lavatrice",
    },
    { id: "parking", label: t("apartment_parking") || "Parcheggio" },
    { id: "elevator", label: t("apartment_elevator") || "Ascensore" },
    { id: "balcony", label: t("apartment_balcony") || "Balcone" },
  ];

  const renderAmenitiesStep = () => (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {t("apartment_amenities") || "Servizi"}
        </h2>
        <p className="text-gray-600 mb-6 text-sm">
          {t("apartment_amenities_desc") || "Seleziona i servizi disponibili"}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-3">
        {amenitiesList.map((amenity) => (
          <div
            key={amenity.id}
            className={`border rounded-md p-3 cursor-pointer transition-colors ${
              formData.amenities.includes(amenity.id)
                ? "border-blue-500 bg-blue-50"
                : "border-gray-200 hover:border-gray-300"
            }`}
            onClick={() => {
              if (formData.amenities.includes(amenity.id)) {
                setFormData({
                  ...formData,
                  amenities: formData.amenities.filter(
                    (id) => id !== amenity.id,
                  ),
                });
              } else {
                setFormData({
                  ...formData,
                  amenities: [...formData.amenities, amenity.id],
                });
              }
            }}
          >
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={formData.amenities.includes(amenity.id)}
                onChange={() => {}}
                className="mr-2"
              />
              <span>{amenity.label}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderPhotosStep = () => (
    <div className="space-y-4">
      <div>
        <h2 className="text-xl font-semibold mb-2">
          {t("apartment_photos") || "Foto"}
        </h2>
        <p className="text-gray-600 mb-6 text-sm">
          {t("apartment_photos_desc") || "Carica le foto dell'appartamento"}
        </p>
      </div>
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
        <div className="mx-auto flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-gray-400 mb-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <p className="text-sm font-medium text-gray-600 mb-1">
            {t("apartment_drag_photos") ||
              "Trascina le foto qui o clicca per caricarle"}
          </p>
          <p className="text-xs text-gray-500">
            {t("apartment_photo_requirements") ||
              "PNG, JPG fino a 5MB ciascuna"}
          </p>
          <input
            type="file"
            multiple
            accept="image/*"
            className="hidden"
            id="photo-upload"
            onChange={(e) => {
              // In a real app, you would handle the file upload here
              // For this example, we'll just set the file names
              const fileNames = Array.from(e.target.files).map(
                (file) => file.name,
              );
              setFormData({
                ...formData,
                photos: fileNames,
              });
            }}
          />
          <Button
            variant="outline"
            size="sm"
            className="mt-4"
            onClick={() => document.getElementById("photo-upload").click()}
          >
            {t("apartment_select_files") || "Seleziona File"}
          </Button>
        </div>
      </div>
      {formData.photos.length > 0 && (
        <div className="mt-4">
          <p className="text-sm font-medium mb-2">
            {t("apartment_selected_files") || "File Selezionati"}:
          </p>
          <ul className="text-sm space-y-1">
            {formData.photos.map((fileName, index) => (
              <li key={index} className="text-gray-600">
                {fileName}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return renderBasicInfoStep();
      case 2:
        return renderLocationStep();
      case 3:
        return renderDetailsStep();
      case 4:
        return renderAmenitiesStep();
      case 5:
        return renderPhotosStep();
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-full bg-white shadow-sm rounded-lg">
      <div className="p-6 h-full">
        <h1 className="text-2xl font-bold mb-2">
          {t("add_apartment") || "Aggiungi un appartamento"}
        </h1>
        <p className="text-gray-600 mb-6">
          {t("add_apartment_description") ||
            "Compila il form per aggiungere un nuovo appartamento."}
        </p>

        <div className="mb-8">
          <div className="flex justify-between text-sm text-gray-500 mb-2">
            <span>
              Passo {currentStep} di {totalSteps}
            </span>
            <span>{Math.round((currentStep / totalSteps) * 100)}%</span>
          </div>
          <Progress
            value={(currentStep / totalSteps) * 100}
            className="h-2 bg-gray-200"
            indicatorClassName="bg-blue-600"
          />
        </div>

        <form
          onSubmit={currentStep === totalSteps ? handleSubmit : nextStep}
          className="h-full flex flex-col"
        >
          <div className="flex-1">{renderStepContent()}</div>

          <div className="flex justify-between mt-8 pt-4 border-t border-gray-200">
            <Button
              type="button"
              variant="outline"
              onClick={prevStep}
              disabled={currentStep === 1}
              className="flex items-center"
            >
              <ChevronLeft className="mr-1 h-4 w-4" />
              {t("previous") || "Indietro"}
            </Button>

            <Button
              type={currentStep === totalSteps ? "submit" : "button"}
              onClick={currentStep === totalSteps ? handleSubmit : nextStep}
              disabled={isLoading}
              className="bg-black text-white hover:bg-gray-800 flex items-center"
            >
              {isLoading ? (
                <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
              ) : null}
              {currentStep === totalSteps ? (
                t("complete") || "Completa"
              ) : (
                <>
                  {t("next") || "Avanti"}
                  <ChevronRight className="ml-1 h-4 w-4" />
                </>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddApartment;
