import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../../lib/supabase";
import { guideService } from "../../services/guideService";
import { helpArticleService } from "../../services/helpArticleService";
import { reservationService } from "../../services/reservationService";
import { showToast } from "../../utils/toast";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import ItemCard from "./ItemCard";
import ItemForm from "./ItemForm";
import { Loader2 } from "lucide-react";

const ItemList = ({
  itemType,
  title,
  addNewLabel,
  items,
  isLoading,
  error,
  onRefetch,
  customEmptyState,
  onAddNew,
}) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const localItems = items || [];

  const handleEdit = (item) => {
    setEditingItem(item);
    setIsDialogOpen(true);
  };

  const handleDelete = async (itemId) => {
    if (!window.confirm(t("confirm_delete"))) return;

    try {
      let success = false;

      // Utilizza il servizio appropriato in base al tipo di elemento
      switch (itemType) {
        case "apartments":
          const { error: apartmentError } = await supabase
            .from("apartments")
            .delete()
            .eq("id", itemId);

          if (apartmentError) throw apartmentError;
          success = true;
          break;

        case "guides":
          const { success: guideSuccess, error: guideError } =
            await guideService.deleteGuide(itemId);
          if (guideError) throw guideError;
          success = guideSuccess;
          break;

        case "help_articles":
          const { success: articleSuccess, error: articleError } =
            await helpArticleService.deleteHelpArticle(itemId);
          if (articleError) throw articleError;
          success = articleSuccess;
          break;

        case "reservations":
          const { success: reservationSuccess, error: reservationError } =
            await reservationService.deleteReservation(itemId);
          if (reservationError) throw reservationError;
          success = reservationSuccess;
          break;

        default:
          // Fallback generico
          const { error: defaultError } = await supabase
            .from(itemType)
            .delete()
            .eq("id", itemId);

          if (defaultError) throw defaultError;
          success = true;
      }

      if (success) {
        if (onRefetch) {
          onRefetch();
        }
        showToast.success(t("delete_success"));
      }
    } catch (error) {
      console.error(`Error deleting ${itemType}:`, error);
      showToast.error(error.message || t("delete_error"));
    }
  };

  const handleFormSubmit = (result) => {
    setIsDialogOpen(false);
    setEditingItem(null);

    if (onRefetch) {
      onRefetch();
    }
  };

  const handleFormCancel = () => {
    setIsDialogOpen(false);
    setEditingItem(null);
  };

  const handleAddNewClick = () => {
    if (onAddNew) {
      onAddNew();
    } else {
      setEditingItem(null);
      setIsDialogOpen(true);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">{title}</h2>

        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button
              onClick={handleAddNewClick}
              className="h-8 px-3 flex items-center gap-1"
            >
              {addNewLabel}
            </Button>
          </DialogTrigger>
          <DialogContent className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[90vw] max-w-3xl max-h-[90vh] overflow-y-auto bg-background p-6 shadow-lg rounded-lg">
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold text-gray-900">
                {editingItem
                  ? `Modifica ${itemType}`
                  : `Aggiungi nuovo ${itemType}`}
              </DialogTitle>
            </DialogHeader>
            <ItemForm
              editingItem={editingItem}
              onSubmit={handleFormSubmit}
              onCancel={handleFormCancel}
              itemType={itemType}
            />
          </DialogContent>
        </Dialog>
      </div>

      {isLoading ? (
        <div className="text-center py-8">
          <div className="flex flex-col items-center gap-2">
            <Loader2 className="h-10 w-10 animate-spin text-primary" />
            <span>{t("loading") || "Caricamento..."}</span>
          </div>
        </div>
      ) : error ? (
        <div className="text-center py-8 text-red-500">
          {error}
          <Button variant="outline" className="ml-2" onClick={onRefetch}>
            {t("retry")}
          </Button>
        </div>
      ) : localItems.length === 0 ? (
        customEmptyState || (
          <div className="text-center py-8 text-gray-500">
            {t("no_items_found") || "Nessun elemento trovato"}
          </div>
        )
      ) : (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          {localItems.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              itemType={itemType}
              onEdit={() => handleEdit(item)}
              onDelete={() => handleDelete(item.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ItemList;
