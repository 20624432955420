//src/auth/components/Login.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, redirect } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import Footer from "../common/Footer";
import { useAuth } from "../../contexts/AuthContext";
import { showToast } from "../../utils/toast";
// Importa supabase direttamente per il magic link se necessario
import { supabase } from "../../lib/supabase";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  // Commento password e setPassword perché non utilizzati
  // const [password, setPassword] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // Rimuovi sendMagicLink se non è definito nel tuo contesto di autenticazione
  const { login, loginWithGoogle } = useAuth();

  // Ottieni il percorso da cui l'utente è stato reindirizzato, o usa /dashboard come default
  const from = location.state?.from?.pathname || "/dashboard";

  // Ottieni i parametri di checkout se presenti
  const checkoutPlan = location.state?.plan;
  const checkoutInterval = location.state?.interval;
  const returnTo = location.state?.returnTo;

  // Controlla se ci sono errori nell'URL (ad esempio dopo un reindirizzamento da Supabase)
  useEffect(() => {
    // Controlla se ci sono parametri di errore nell'URL
    const params = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash.replace("#", ""));

    const error = params.get("error") || hashParams.get("error");
    const errorCode = params.get("error_code") || hashParams.get("error_code");
    const success = params.get("success");

    if (success === "true") {
      showToast.success(
        "Pagamento completato con successo! Accedi per iniziare a utilizzare GuestSuite.",
      );
    }

    if (error) {
      // Log interno per debugging (visibile solo nella console)
      console.error("Auth error:", { error, errorCode });

      // Questo è un ban utente o altro errore di accesso
      if (error === "access_denied" && errorCode === "user_banned") {
        console.log("Utente bannato - mostriamo un messaggio generico");
      }

      // Mostra un messaggio generico all'utente
      showToast.error("Login fallito");

      // Pulisci l'URL rimuovendo i parametri di errore
      navigate("/login", { replace: true });
    }
  }, [location, navigate]);

  // Gestisce il login con Google tramite Supabase
  const handleGoogleLogin = async () => {
    setLoading(true);

    try {
      const { success, error: loginError } = await loginWithGoogle();

      if (!success) throw loginError;

      // Non è necessario reindirizzare qui, poiché il reindirizzamento è gestito da Supabase OAuth
    } catch (error) {
      console.error("Errore durante il login con Google:", error);
      showToast.error("Login fallito");
      setLoading(false);
    }
  };

  // Gestisce l'invio del magic link
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Implementazione diretta del magic link usando supabase
      const redirectUrl =
        process.env.NODE_ENV === "production"
          ? `https://guestsuite.app/auth/callback`
          : `http://localhost:3000/auth/callback`;

      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: redirectUrl,
        },
      });

      if (error) throw error;

      setIsSent(true);
      showToast.success("Link di accesso inviato alla tua email");
    } catch (error) {
      console.error("Errore nell'invio del magic link:", error);
      showToast.error("Invio del link di accesso fallito");
    } finally {
      setLoading(false);
    }
  };

  // Gestisce il login con email e password - commentato perché non utilizzato
  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     const { success, error: loginError } = await login(email, password);

  //     if (!success) throw loginError;

  //     console.log("Login successful, redirecting to:", from);

  //     // Se ci sono parametri di checkout, reindirizza alla pagina di checkout
  //     if (returnTo && checkoutPlan) {
  //       navigate(returnTo, {
  //         state: {
  //           fromLogin: true,
  //           plan: checkoutPlan,
  //           interval: checkoutInterval || 'month'
  //         }
  //       });
  //     } else {
  //       navigate(from, { replace: true });
  //     }
  //   } catch (error) {
  //     console.error("Errore durante il login:", error);
  //     showToast.error("Login fallito");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="absolute top-4 left-4 z-10">
        <Link to="/">
          <Button
            variant="ghost"
            className="text-sm text-gray-600 hover:text-primary flex items-center gap-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
            Home
          </Button>
        </Link>
      </div>

      <div className="flex-grow flex items-center justify-center bg-white px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md relative">
          {!isSent ? (
            <>
              <div className="text-center mb-8 px-4">
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
                  Benvenuto su GuestSuite
                </h1>
                <p className="text-sm sm:text-base text-gray-600">
                  Accedi o crea un nuovo account
                </p>
              </div>

              <div className="space-y-6">
                {/* Pulsante Google con Supabase */}
                <Button
                  onClick={handleGoogleLogin}
                  disabled={loading}
                  className="w-full h-12 bg-white border-2 border-gray-300 hover:bg-gray-50 text-gray-700 font-medium text-sm sm:text-base flex items-center justify-center gap-2"
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t("loading") || "Caricamento..."}
                    </>
                  ) : (
                    <>
                      <svg
                        width="18"
                        height="18"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#FFC107"
                          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                        <path
                          fill="#FF3D00"
                          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        />
                        <path
                          fill="#4CAF50"
                          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        />
                        <path
                          fill="#1976D2"
                          d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                      </svg>
                      Accedi con Google
                    </>
                  )}
                </Button>

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      Oppure continua con
                    </span>
                  </div>
                </div>

                {/* Form per magic link */}
                <form onSubmit={handleEmailLogin} className="space-y-4">
                  <Input
                    type="email"
                    placeholder="Il tuo indirizzo email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full h-12 px-4 border-2 rounded-lg focus:ring-primary text-base"
                  />
                  <Button
                    type="submit"
                    disabled={loading}
                    className="w-full h-12 bg-primary hover:bg-primary/90 text-white font-medium text-sm sm:text-base"
                  >
                    {loading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {t("loading") || "Caricamento..."}
                      </>
                    ) : (
                      "Continua con Email"
                    )}
                  </Button>
                </form>
              </div>
            </>
          ) : (
            <div className="text-center bg-white p-6 sm:p-8 rounded-2xl shadow-lg backdrop-blur-sm mx-4">
              <div className="text-4xl sm:text-6xl mb-4 sm:mb-6">✉️</div>
              <h3 className="text-xl sm:text-2xl font-semibold mb-3 sm:mb-4">
                Link di Accesso
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-4 sm:mb-6">
                Abbiamo inviato un link di accesso a:
                <br />
                <span className="font-medium">{email}</span>
              </p>

              <Button
                variant="ghost"
                className="mt-4 sm:mt-6 text-sm sm:text-base"
                onClick={() => setIsSent(false)}
              >
                Usa un altro metodo
              </Button>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
