import { useState, useEffect } from "react";
import { supabase } from "../lib/supabase";

export const useSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setIsLoading(true);
        
        // Ottieni prima l'ID dell'utente corrente
        const { data: userData, error: userError } = await supabase.auth.getUser();
        
        if (userError) {
          console.error("Error fetching user:", userError);
          throw userError;
        }
        
        if (!userData?.user?.id) {
          throw new Error("Utente non autenticato");
        }
        
        // Chiama la funzione RPC con il parametro p_user_id
        const { data, error: rpcError } = await supabase.rpc('get_user_subscription', {
          p_user_id: userData.user.id
        });
        
        if (rpcError) {
          console.error("Error fetching subscription from RPC:", rpcError);
          throw rpcError;
        }
        
        if (data && data.length > 0) {
          // Trasforma la risposta nel formato atteso dal componente
          const subscriptionData = {
            active: data[0].status === 'active' || data[0].status === 'trialing',
            plan_type: data[0].plan_type,
            status: data[0].status,
            monthly_event_limit: data[0].monthly_event_limit,
            current_period_end: data[0].current_period_end
          };
          setSubscription(subscriptionData);
        } else {
          // Nessuna sottoscrizione trovata
          setSubscription(null);
        }
      } catch (err) {
        console.error("Error fetching subscription:", err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscription();

    // Opzionale: polling per aggiornamenti della subscription
    const interval = setInterval(fetchSubscription, 60000); // Controlla ogni minuto

    return () => clearInterval(interval);
  }, []);

  const refetch = async () => {
    try {
      setIsLoading(true);
      
      // Ottieni prima l'ID dell'utente corrente
      const { data: userData, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        console.error("Error fetching user:", userError);
        throw userError;
      }
      
      if (!userData?.user?.id) {
        throw new Error("Utente non autenticato");
      }
      
      // Chiama la funzione RPC con il parametro p_user_id
      const { data, error: rpcError } = await supabase.rpc('get_user_subscription', {
        p_user_id: userData.user.id
      });
      
      if (rpcError) {
        console.error("Error fetching subscription from RPC:", rpcError);
        throw rpcError;
      }
      
      if (data && data.length > 0) {
        // Trasforma la risposta nel formato atteso dal componente
        const subscriptionData = {
          active: data[0].status === 'active' || data[0].status === 'trialing',
          plan_type: data[0].plan_type,
          status: data[0].status,
          monthly_event_limit: data[0].monthly_event_limit,
          current_period_end: data[0].current_period_end
        };
        setSubscription(subscriptionData);
      } else {
        // Nessuna sottoscrizione trovata
        setSubscription(null);
      }
    } catch (err) {
      console.error("Error fetching subscription:", err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    subscription,
    isLoading,
    error,
    refetch,
  };
};
