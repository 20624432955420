// Modifica da fare al tuo AuthContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { supabase } from "../lib/supabase";
import { showToast } from "../utils/toast";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkAuth = useCallback(async () => {
    try {
      console.log("AuthContext - Checking auth...");
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();

      if (error) {
        console.error("Errore nel recupero della sessione:", error);
        setUser(null);
      } else if (session) {
        console.log("AuthContext - Session found, getting user...");
        const {
          data: { user },
        } = await supabase.auth.getUser();
        setUser(user);
        console.log("AuthContext - User set:", user?.email);
      } else {
        console.log("AuthContext - No session found, setting user to null");
        setUser(null);
      }
    } catch (error) {
      console.error("Errore durante il controllo dell'autenticazione:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
    return user;
  }, []);

  useEffect(() => {
    // Verifica la sessione all'avvio
    console.log("AuthContext - Initial auth check");
    checkAuth();

    // Configura il listener per i cambiamenti di autenticazione
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Auth state changed:", event);

        if (session && session.user) {
          setUser(session.user);
        } else {
          setUser(null);
        }

        setLoading(false);
      },
    );

    // Pulisci il listener quando il componente viene smontato
    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, [checkAuth]);

  // Funzioni di autenticazione
  const login = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      return { success: true, data };
    } catch (error) {
      console.error("Errore durante il login:", error);
      return { success: false, error };
    }
  };

  const loginWithGoogle = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
        },
      });

      if (error) throw error;

      return { success: true, data };
    } catch (error) {
      console.error("Errore durante il login con Google:", error);
      return { success: false, error };
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();

      if (error) throw error;

      return { success: true };
    } catch (error) {
      console.error("Errore durante il logout:", error);
      return { success: false, error };
    }
  };

  const value = {
    user,
    loading,
    login,
    loginWithGoogle,
    logout,
    checkAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
