import { useState, useEffect, useCallback } from "react";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { Check, Loader2, Download, RefreshCw } from "lucide-react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/card";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "../ui/table";
import { supabase } from "../../lib/supabase";
import { showToast } from "../../utils/toast";

import { loadStripe } from "@stripe/stripe-js";

// Modifica questa riga per usare window.location.origin
const API_BASE_URL = window.location.origin;

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY,
);

const BillingDetails = ({ user }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [loadingCheckout, setLoadingCheckout] = useState({});
  const [isPlansLoading, setIsPlansLoading] = useState(false);
  const [availablePlans, setAvailablePlans] = useState([]);

  // Carica i piani disponibili da Stripe
  const fetchPlans = async () => {
    try {
      setIsPlansLoading(true);
      const { data, error } = await supabase.rpc('get_available_plans');
      
      if (error) {
        throw error;
      }
      
      if (data?.success && data?.plans) {
        setAvailablePlans(data.plans);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
      showToast.error(t("errors.plansLoadingFailed"));
    } finally {
      setIsPlansLoading(false);
    }
  };

  // Estrai la funzione fetchSubscriptionData per poterla riutilizzare
  const fetchSubscriptionData = async () => {
    try {
      setLoadingData(true);
      setError(null);

      // Ottieni prima l'ID dell'utente corrente
      const { data: userData, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        console.error("Error fetching user:", userError);
        throw new Error(`Errore nel recupero dell'utente: ${userError.message}`);
      }
      
      if (!userData?.user?.id) {
        throw new Error("Utente non autenticato");
      }
      
      // Usa la funzione RPC con il parametro p_user_id
      const { data: subscriptionData, error: subscriptionError } = await supabase.rpc('get_user_subscription', {
        p_user_id: userData.user.id
      });
      
      if (subscriptionError) {
        throw new Error(`Errore nel recupero della subscription: ${subscriptionError.message}`);
      }
      
      if (subscriptionData && subscriptionData.length > 0) {
        // Trasforma la risposta nel formato atteso dal componente
        const subscriptionInfo = {
          plan: subscriptionData[0].plan_type,
          status: subscriptionData[0].status,
          current_period_end: subscriptionData[0].current_period_end,
          amount_paid: "0.00", // Assumi un valore di default o recuperalo da altra parte
          interval: "month" // Assumi un valore di default o recuperalo da altra parte
        };
        
        setSubscription(subscriptionInfo);
        
        // Recupera le fatture solo se esiste una subscription attiva
        if (subscriptionInfo.status === "active" || subscriptionInfo.status === "trialing") {
          try {
            // Recupera l'ID utente per le fatture
            const { data: invoicesData, error: invoicesError } = await supabase.rpc('get_user_invoices', {
              p_user_id: userData.user.id
            });
            
            if (invoicesError) {
              console.error("Errore nel recupero delle fatture:", invoicesError);
            } else if (invoicesData?.success) {
              setInvoices(invoicesData.invoices || []);
            }
          } catch (invoiceError) {
            console.error("Errore nel recupero delle fatture:", invoiceError);
          }
        }
      } else {
        // Nessuna subscription trovata
        setSubscription(null);
      }
    } catch (error) {
      console.error("Errore dettagliato:", error);
      setError(
        error.message ||
          "Impossibile caricare i dati della sottoscrizione. Riprova più tardi."
      );
      showToast.error(error.message || t("error_loading_data"));
    } finally {
      setLoadingData(false);
    }
  };

  // Carica i dati della sottoscrizione e delle fatture all'avvio
  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const redirectToCustomerPortal = async () => {
    try {
      setLoading(true);
      // Ottieni la sessione corrente da Supabase
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError || !session) {
        throw new Error("Sessione non valida. Effettua nuovamente il login.");
      }

      const response = await fetch("/api/create-portal-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.access_token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to create portal session");
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error("Error:", error);
      showToast.error(t("portal_error"));
    } finally {
      setLoading(false);
    }
  };

  // Modifica questa parte per gestire meglio la corrispondenza tra subscription.plan e i piani disponibili
  const findCurrentPlan = (subscription, plans) => {
    if (!subscription?.plan || !plans || !Array.isArray(plans)) {
      return null;
    }

    // Cerca una corrispondenza esatta
    let plan = plans.find((p) => p.id === subscription.plan);

    // Se non trova una corrispondenza esatta, prova a normalizzare i nomi dei piani
    if (!plan) {
      const normalizedPlanName = subscription.plan
        .toLowerCase()
        .replace(/\s+/g, "");
      plan = plans.find(
        (p) => p.id.toLowerCase().replace(/\s+/g, "") === normalizedPlanName,
      );
    }

    // Se ancora non trova, usa il primo piano disponibile o crea un piano generico
    if (!plan && plans.length > 0) {
      console.warn(
        `Piano '${subscription.plan}' non trovato nei piani disponibili. Usando il primo piano disponibile.`,
      );
      plan = plans[0];
    } else if (!plan) {
      console.warn(
        `Piano '${subscription.plan}' non trovato e nessun piano disponibile. Creando un piano generico.`,
      );
      plan = {
        id: subscription.plan,
        name:
          subscription.plan.charAt(0).toUpperCase() +
          subscription.plan.slice(1),
        monthly: {
          amount: parseFloat(subscription.amount_paid) || 0,
          price: `€${subscription.amount_paid || 0}`,
          interval: "mese",
        },
        yearly: {
          amount: parseFloat(subscription.amount_paid) * 12 || 0,
          price: `€${parseFloat(subscription.amount_paid) * 12 || 0}`,
          interval: "anno",
        },
        description: [],
      };
    }

    return plan;
  };

  // Usa la nuova funzione per determinare il piano corrente
  const currentPlan = findCurrentPlan(subscription, plans);

  // Modifica la funzione getPriceDetails per usare sempre il prezzo dalla subscription
  const getPriceDetails = () => {
    // Usa sempre il prezzo dalla subscription
    const amount = parseFloat(subscription?.amount_paid) || 0;

    // Formatta il prezzo correttamente
    const formattedPrice = `€${amount.toFixed(2)}`;

    // Determina l'intervallo
    const interval = subscription?.interval === "year" ? "anno" : "mese";

    return {
      amount: amount,
      formattedPrice: formattedPrice,
      interval: interval,
    };
  };

  const priceDetails = getPriceDetails();

  // Formatta la data di fine del periodo corrente
  const formatDate = (dateString) => {
    if (!dateString) return "";
    return new Date(dateString).toLocaleDateString();
  };

  const syncSubscription = async () => {
    try {
      setLoadingData(true);

      // Ottieni la sessione corrente da Supabase
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError || !session) {
        throw new Error("Sessione non valida. Effettua nuovamente il login.");
      }

      const response = await fetch(`${API_BASE_URL}/api/subscription/sync`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.access_token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Errore nella sincronizzazione");
      }

      const data = await response.json();
      showToast.success("Abbonamento sincronizzato con successo");

      // Ricarica i dati
      await fetchSubscriptionData();
    } catch (error) {
      console.error("Errore:", error);
      showToast.error(error.message || "Errore nella sincronizzazione");
    } finally {
      setLoadingData(false);
    }
  };

  const handleCheckout = useCallback(
    async (planId, priceId) => {
      try {
        setLoadingCheckout((prev) => ({ ...prev, [planId]: true }));

        if (!priceId) {
          throw new Error("Price ID non trovato");
        }

        const {
          data: { session },
          error: sessionError,
        } = await supabase.auth.getSession();

        if (sessionError || !session) {
          throw new Error("Sessione non valida. Effettua nuovamente il login.");
        }

        const response = await fetch(
          `${API_BASE_URL}/api/subscription/checkout`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${session.access_token}`,
            },
            body: JSON.stringify({
              priceId,
              planId,
              email: user.email,
              trialPeriodDays: 14,
              cancel_url: window.location.href,
            }),
          },
        );

        const data = await response.json();

        if (!data.sessionId) {
          throw new Error(
            data.error || "Errore nella creazione della sessione",
          );
        }

        const stripe = await stripePromise;
        if (!stripe)
          throw new Error("Stripe non è stato caricato correttamente");

        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (error) {
          throw error;
        }
      } catch (error) {
        console.error("Errore durante il processo di checkout:", error);
        showToast.error(
          error.message || "Si è verificato un errore durante il checkout",
        );
      } finally {
        setLoadingCheckout((prev) => ({ ...prev, [planId]: false }));
      }
    },
    [user.email],
  );

  // Renderizza l'header comune a tutte le viste
  const renderHeader = () => (
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-2xl font-bold">{t("subscription_management")}</h2>
    </div>
  );

  const IntervalSwitch = () => {
    // Calcola lo sconto medio tra tutti i piani
    const averageDiscount = Math.round(
      plans.reduce((acc, plan) => {
        const yearlyDiscount =
          plan.monthly.amount > 0
            ? ((plan.monthly.amount * 12 - plan.yearly.amount) /
                (plan.monthly.amount * 12)) *
              100
            : 0;
        return acc + yearlyDiscount;
      }, 0) / plans.length,
    );

    return (
      <div className="flex flex-col items-center mb-8">
        <div className="bg-gray-100 p-2 rounded-lg inline-flex items-center gap-4">
          <button
            className={`px-4 py-2 rounded cursor-pointer transition-all ${
              selectedInterval === "monthly"
                ? "bg-white shadow-sm font-medium"
                : "hover:bg-white/50"
            }`}
            onClick={() => setSelectedInterval("monthly")}
          >
            Mensile
          </button>
          <button
            className={`px-4 py-2 rounded cursor-pointer transition-all flex items-center gap-2 ${
              selectedInterval === "yearly"
                ? "bg-white shadow-sm font-medium"
                : "hover:bg-white/50"
            }`}
            onClick={() => setSelectedInterval("yearly")}
          >
            Annuale
            {averageDiscount > 0 && (
              <span className="bg-green-100 text-green-800 text-xs font-medium px-2 py-1 rounded">
                Fino a -{averageDiscount}%
              </span>
            )}
          </button>
        </div>
      </div>
    );
  };

  if (loadingData) {
    return (
      <div className="p-4">
        {renderHeader()}
        <div className="flex items-center justify-center h-64">
          <div className="flex flex-col items-center gap-2">
            <Loader2 className="h-10 w-10 animate-spin text-primary" />
            <span className="text-gray-600">{t("loading")}</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        {renderHeader()}
        <div className="p-6 bg-red-50 border border-red-200 rounded-lg text-center">
          <p className="text-red-600">{error}</p>
          <Button className="mt-4" onClick={() => window.location.reload()}>
            {t("retry")}
          </Button>
        </div>
      </div>
    );
  }

  if (!subscription) {
    return (
      <div className="p-4">
        {renderHeader()}
        <div className="bg-white p-6 rounded-lg border">
          <p className="mb-4 font-medium text-lg">
            Non hai un piano disponibile
          </p>
          <p className="text-sm text-muted-foreground mb-4">
            Sottoscrivi un piano per accedere a tutte le funzionalità di
            GuestSuite.
          </p>

          {/* Switch per prezzi annuali/mensili */}
          <IntervalSwitch />

          {/* Griglia dei piani */}
          {loadingData ? (
            <div className="flex justify-center items-center py-12">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <span className="ml-2">Caricamento piani...</span>
            </div>
          ) : plans && plans.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-6">
              {plans
                .sort((a, b) => {
                  const order = { starter: 1, pro: 2, enterprise: 3 };
                  return order[a.id] - order[b.id];
                })
                .map((plan) => {
                  const currentPrice =
                    selectedInterval === "monthly" ? plan.monthly : plan.yearly;
                  const monthlyPrice =
                    selectedInterval === "monthly"
                      ? plan.monthly.amount
                      : Math.round(plan.yearly.amount / 12);

                  return (
                    <Card
                      key={plan.id}
                      className={`relative group transition-all duration-300 cursor-pointer ${
                        plan.id === "pro"
                          ? "border-[1.5px] border-primary hover:shadow-sm"
                          : "border border-gray-200 hover:border-primary/50 hover:shadow-sm"
                      }`}
                      onClick={() =>
                        handleCheckout(plan.id, currentPrice.priceId)
                      }
                    >
                      <CardContent className="p-3">
                        <div>
                          <h3 className="text-base font-bold mb-1 text-black">
                            {plan.name}
                          </h3>
                          <div className="space-y-1">
                            <div className="flex items-baseline">
                              <span className="text-lg font-bold text-black">
                                €{monthlyPrice}
                              </span>
                              <span className="text-gray-600 ml-1 text-xs">
                                /mese
                              </span>
                            </div>
                            {selectedInterval === "yearly" && (
                              <div className="text-xs text-gray-500">
                                €{plan.yearly.amount} annuale
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-2">
                          <ul className="space-y-1">
                            {plan.description
                              .slice(0, 3)
                              .map((feature, index) => (
                                <li key={index} className="flex items-start">
                                  <Check className="h-3 w-3 text-green-500 mr-1 mt-0.5 flex-shrink-0" />
                                  <span className="text-xs text-gray-700">
                                    {feature}
                                  </span>
                                </li>
                              ))}
                          </ul>
                        </div>
                        {loadingCheckout[plan.id] && (
                          <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
                            <Loader2 className="h-6 w-6 animate-spin text-primary" />
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  );
                })}
            </div>
          ) : (
            <div className="text-center py-6 mb-6">
              <p className="text-gray-500">
                Nessun piano disponibile al momento.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (!["active", "trialing"].includes(subscription.status)) {
    return (
      <div className="p-4">
        {renderHeader()}
        <div className="bg-white p-6 rounded-lg border">
          <div className="flex items-center gap-2 mb-4">
            <p className="font-medium text-lg">
              Piano {subscription.plan} - Non attivo
            </p>
            <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded-full">
              Cancellato
            </span>
          </div>

          {/* Mostra il prezzo attuale */}
          <p className="text-sm text-muted-foreground mb-2">
            Prezzo attuale: {priceDetails.formattedPrice}/
            {priceDetails.interval}
          </p>

          {/* Mostra la data di scadenza */}
          {subscription.current_period_end && (
            <div className="mb-4 p-3 border border-amber-200 rounded bg-amber-50">
              <p className="text-sm font-medium text-amber-800">
                <span className="flex items-center">
                  ⚠️ Ultimo giorno di accesso:{" "}
                  {formatDate(subscription.current_period_end)}
                </span>
              </p>
              <p className="text-xs text-amber-700 mt-1">
                Dopo questa data non avrai più accesso ai servizi premium.
              </p>
            </div>
          )}

          <div className="space-y-4">
            <p className="text-sm text-muted-foreground">
              Il tuo abbonamento è stato cancellato ma puoi continuare a
              utilizzare i servizi fino alla data di scadenza. Puoi riattivare
              l'abbonamento in qualsiasi momento dal portale clienti.
            </p>

            <div className="flex flex-col sm:flex-row gap-3">
              <Button onClick={redirectToCustomerPortal} disabled={loading}>
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {t("loading")}
                  </>
                ) : (
                  "Gestisci abbonamento"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4 md:space-y-6 p-4">
      {renderHeader()}

      {/* Piano Attuale */}
      <Card className="bg-white">
        <CardHeader>
          <CardTitle>{t("current_plan")}</CardTitle>
          <CardDescription>{t("plan_description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
              <div>
                <h4 className="text-xl font-semibold">
                  {subscription.plan.charAt(0).toUpperCase() +
                    subscription.plan.slice(1)}
                </h4>
                <p className="text-lg font-medium text-primary mt-1">
                  {priceDetails.formattedPrice}/{priceDetails.interval}
                </p>
                {subscription?.current_period_end && (
                  <p className="text-sm text-muted-foreground mt-1">
                    <span className="font-medium text-green-700">
                      Prossimo rinnovo:
                    </span>{" "}
                    {formatDate(subscription.current_period_end)}
                  </p>
                )}
                <div className="mt-2">
                  <span
                    className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                      ["active", "trialing"].includes(subscription?.status)
                        ? subscription?.status === "trialing"
                          ? "bg-yellow-100 text-yellow-800"
                          : "bg-green-100 text-green-800"
                        : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {subscription?.status === "trialing"
                      ? t("trialing")
                      : subscription?.status === "active"
                        ? t("active")
                        : t("inactive")}
                  </span>
                </div>
              </div>
              <div>
                <Button
                  onClick={redirectToCustomerPortal}
                  disabled={loading}
                  className="w-full sm:w-auto"
                >
                  {loading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t("loading")}
                    </>
                  ) : (
                    t("manage_subscription")
                  )}
                </Button>
              </div>
            </div>

            {/* Funzionalità del piano - Sezione migliorata */}
            <div className="pt-4 border-t">
              <h5 className="font-medium mb-2">{t("plan_description")}</h5>
              {currentPlan?.description &&
              currentPlan.description.length > 0 ? (
                <ul className="space-y-2">
                  {currentPlan.description.map((feature, index) => (
                    <li key={index} className="text-sm flex items-center">
                      <Check className="h-4 w-4 mr-2 text-green-500 flex-shrink-0" />
                      <span className="flex-1">{feature}</span>
                    </li>
                  ))}
                </ul>
              ) : subscription?.max_apartments ? (
                <ul className="space-y-2">
                  <li className="text-sm flex items-center">
                    <Check className="h-4 w-4 mr-2 text-green-500 flex-shrink-0" />
                    <span className="flex-1">
                      {subscription.max_apartments < 0
                        ? t("unlimited_apartments")
                        : t("max_apartments", {
                            count: subscription.max_apartments,
                          })}
                    </span>
                  </li>
                  <li className="text-sm flex items-center">
                    <Check className="h-4 w-4 mr-2 text-green-500 flex-shrink-0" />
                    <span className="flex-1">{t("website_included")}</span>
                  </li>
                  <li className="text-sm flex items-center">
                    <Check className="h-4 w-4 mr-2 text-green-500 flex-shrink-0" />
                    <span className="flex-1">{t("digital_guides")}</span>
                  </li>
                </ul>
              ) : (
                <p className="text-sm text-muted-foreground">
                  {t("no_description_available")}
                </p>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Fatture Recenti */}
      {invoices.length > 0 && (
        <Card className="bg-white">
          <CardHeader>
            <CardTitle>{t("recent_invoices")}</CardTitle>
            <CardDescription>{t("invoices_description")}</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[100px]">{t("date")}</TableHead>
                    <TableHead className="w-[100px]">{t("amount")}</TableHead>
                    <TableHead className="w-[100px]">{t("status")}</TableHead>
                    <TableHead className="w-[60px] text-right">
                      {t("invoice")}
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {invoices.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell>{formatDate(invoice.date)}</TableCell>
                      <TableCell>€{invoice.amount.toFixed(2)}</TableCell>
                      <TableCell>
                        <span
                          className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                            invoice.status === "paid"
                              ? "bg-green-100 text-green-800"
                              : invoice.status === "open"
                                ? "bg-blue-100 text-blue-800"
                                : "bg-red-100 text-red-800"
                          }`}
                        >
                          {invoice.status === "paid"
                            ? t("paid")
                            : invoice.status === "open"
                              ? t("pending")
                              : t("failed")}
                        </span>
                      </TableCell>
                      <TableCell className="text-right">
                        {invoice.pdf && (
                          <Button variant="ghost" size="sm" asChild>
                            <a
                              href={invoice.pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Download className="h-4 w-4" />
                            </a>
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default BillingDetails;
