import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import FeatureLayout from "../../../components/layouts/FeatureLayout";
import { Button } from "../../ui/button";

const IdentityVerification = () => {
  const { t } = useTranslation();

  return (
    <FeatureLayout>
      <Helmet>
        <title>{t("features_identity_verification_title")} - GuestSuite</title>
        <meta
          name="description"
          content={t("features_identity_verification_description")}
        />
      </Helmet>

      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-6">
            {t("features_identity_verification_title")}
          </h1>

          <div className="prose max-w-none">
            <p className="text-xl text-muted-foreground mb-8">
              {t("features_identity_verification_description")}
            </p>

            <div className="grid gap-8 md:grid-cols-2 my-12">
              <div className="bg-white p-6 rounded-lg shadow-sm border">
                <h3 className="text-xl font-semibold mb-4">
                  {t("features_how_it_works")}
                </h3>
                <ul className="space-y-3">
                  <li>✓ {t("features_id_scan")}</li>
                  <li>✓ {t("features_real_time_verification")}</li>
                  <li>✓ {t("features_gdpr_compliant")}</li>
                  <li>✓ {t("features_secure_storage")}</li>
                </ul>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-sm border">
                <h3 className="text-xl font-semibold mb-4">
                  {t("features_benefits")}
                </h3>
                <ul className="space-y-3">
                  <li>✓ {t("features_time_saving")}</li>
                  <li>✓ {t("features_error_reduction")}</li>
                  <li>✓ {t("features_enhanced_security")}</li>
                  <li>✓ {t("features_regulatory_compliance")}</li>
                </ul>
              </div>
            </div>

            <div className="text-center mt-12">
              <Button size="lg" className="bg-primary text-white">
                {t("features_try_free")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FeatureLayout>
  );
};

export default IdentityVerification;
