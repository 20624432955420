import { CreditCard, Upload, X, RefreshCw } from "lucide-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showToast } from "../../utils/toast";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { useAuth } from "../../contexts/AuthContext";
import { supabase } from "../../lib/supabase";

const AccountDetails = ({ user: initialUser }) => {
  const { t } = useTranslation();
  const { user: authUser } = useAuth();
  const [user, setUser] = useState(initialUser || authUser);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showImageOptions, setShowImageOptions] = useState(false);

  // Stato per i modali
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [personalDetails, setPersonalDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    tax_id: "",
  });

  const [openDialog, setOpenDialog] = useState("");
  const [isModified, setIsModified] = useState(false);

  // Carica i dati del profilo all'avvio
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);

        // Ottieni l'utente corrente da Supabase
        const {
          data: { user: currentUser },
        } = await supabase.auth.getUser();

        if (!currentUser) {
          throw new Error("Utente non autenticato");
        }

        console.log("Fetching profile for user:", currentUser.id);

        // Ottieni il profilo dalla tabella profiles
        const { data, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", currentUser.id)
          .single();

        if (error) {
          console.error("Error fetching profile:", error);

          // Se il profilo non esiste, crealo
          if (error.code === "PGRST116") {
            console.log("Profile not found, creating one...");
            const { data: newProfile, error: insertError } = await supabase
              .from("profiles")
              .insert([{ id: currentUser.id }])
              .select();

            if (insertError) {
              throw insertError;
            }

            setUser({ ...currentUser, ...newProfile[0] });
            updatePersonalDetails(newProfile[0]);
          } else {
            throw error;
          }
        } else {
          console.log("Profile data loaded:", data);
          setUser({ ...currentUser, ...data });
          updatePersonalDetails(data);
        }
      } catch (error) {
        console.error("Error in fetchProfile:", error);
        showToast.error(t("error_fetching_profile"));
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  // Funzione per aggiornare i dettagli personali
  const updatePersonalDetails = (data) => {
    console.log("Data:", data);
    if (!data) return;

    setPersonalDetails({
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: user?.email || "",
      phone: data.phone || "",
      address1: data.address1 || "",
      address2: data.address2 || "",
      city: data.city || "",
      state: data.state || "",
      country: data.country || "",
      zip_code: data.zip_code || "",
      tax_id: data.tax_id || "",
    });
  };

  const handleInputChange = (field, value) => {
    setPersonalDetails((prev) => {
      const updatedDetails = { ...prev, [field]: value };
      setIsModified(
        JSON.stringify(updatedDetails) !==
          JSON.stringify({
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            email: user?.email || "",
            phone: user?.phone || "",
            address1: user?.address1 || "",
            address2: user?.address2 || "",
            city: user?.city || "",
            state: user?.state || "",
            country: user?.country || "",
            zip_code: user?.zip_code || "",
            tax_id: user?.tax_id || "",
          }),
      );
      return updatedDetails;
    });
  };

  // Aggiornamento dei dettagli personali con Supabase
  const handlePersonalDetailsSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { user: currentUser },
      } = await supabase.auth.getUser();

      if (!currentUser) {
        throw new Error("Utente non autenticato");
      }

      const { data, error } = await supabase
        .from("profiles")
        .update({
          first_name: personalDetails.first_name,
          last_name: personalDetails.last_name,
          phone: personalDetails.phone,
          address1: personalDetails.address1,
          address2: personalDetails.address2,
          city: personalDetails.city,
          state: personalDetails.state,
          country: personalDetails.country,
          zip_code: personalDetails.zip_code,
          tax_id: personalDetails.tax_id,
          updated_at: new Date(),
        })
        .eq("id", currentUser.id)
        .select();

      if (error) throw error;

      setUser({ ...currentUser, ...data[0] });
      showToast.success(t("account_details_updated"));
      setIsModified(false);
    } catch (error) {
      console.error("Error updating user details:", error);
      showToast.error(error.message || t("error_updating_details"));
    }
  };

  const handleOpenDialog = (dialogName) => {
    setOpenDialog(dialogName);
  };

  const handleCloseDialog = () => {
    setOpenDialog("");
  };

  // Upload dell'immagine con Supabase Storage
  const handleImageUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.startsWith("image/")) {
        showToast.error(t("please_select_image"));
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        showToast.error(t("image_too_large"));
        return;
      }

      setIsUploading(true);
      setShowImageOptions(false);

      // Ottieni l'utente corrente da Supabase
      const {
        data: { user: currentUser },
      } = await supabase.auth.getUser();

      if (!currentUser) {
        throw new Error("Utente non autenticato");
      }

      // Se c'è già un'immagine, elimina il file precedente
      if (user?.avatar_url) {
        // Estrai il nome del file dall'URL
        const oldFileName = user.avatar_url.split("/").pop();
        if (oldFileName) {
          // Prova a eliminare il file precedente, ma non bloccare se fallisce
          try {
            await supabase.storage.from("avatars").remove([oldFileName]);
            console.log("Previous image removed:", oldFileName);
          } catch (removeError) {
            console.warn("Could not remove previous image:", removeError);
          }
        }
      }

      // Genera un nome file unico
      const fileExt = file.name.split(".").pop();
      const fileName = `${currentUser.id}-${Math.random().toString(36).substring(2)}.${fileExt}`;

      // Carica il file nel bucket 'avatars'
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(fileName, file, {
          cacheControl: "3600",
          upsert: true, // Sovrascrive il file se esiste già
        });

      if (uploadError) throw uploadError;

      // Ottieni l'URL pubblico del file
      const {
        data: { publicUrl },
      } = supabase.storage.from("avatars").getPublicUrl(fileName);

      // Aggiorna il profilo con il nuovo URL dell'immagine
      const { data, error } = await supabase
        .from("profiles")
        .update({
          avatar_url: publicUrl,
          updated_at: new Date(),
        })
        .eq("id", currentUser.id)
        .select();

      if (error) throw error;

      // Aggiorna lo stato locale
      setUser({ ...currentUser, ...data[0] });

      showToast.success(t("image_updated"));
    } catch (error) {
      console.error("Error uploading image:", error);
      showToast.error(t("error_uploading_image"));
    } finally {
      setIsUploading(false);
    }
  };

  // Rimuovi l'immagine
  const handleRemoveImage = async () => {
    try {
      if (!user?.avatar_url) {
        return; // Nessuna immagine da rimuovere
      }

      setIsRemoving(true);
      setShowImageOptions(false);

      // Ottieni l'utente corrente da Supabase
      const {
        data: { user: currentUser },
      } = await supabase.auth.getUser();

      if (!currentUser) {
        throw new Error("Utente non autenticato");
      }

      // Estrai il nome del file dall'URL
      const fileName = user.avatar_url.split("/").pop();

      if (fileName) {
        // Elimina il file dallo storage
        const { error: removeError } = await supabase.storage
          .from("avatars")
          .remove([fileName]);

        if (removeError) {
          console.warn("Error removing image file:", removeError);
          // Continua comunque ad aggiornare il profilo
        }
      }

      // Aggiorna il profilo rimuovendo l'URL dell'immagine
      const { data, error } = await supabase
        .from("profiles")
        .update({
          avatar_url: null,
          updated_at: new Date(),
        })
        .eq("id", currentUser.id)
        .select();

      if (error) throw error;

      // Aggiorna lo stato locale
      setUser({ ...currentUser, ...data[0] });

      showToast.success(t("image_removed"));
    } catch (error) {
      console.error("Error removing image:", error);
      showToast.error(t("error_removing_image"));
    } finally {
      setIsRemoving(false);
    }
  };

  // Mostra/nascondi le opzioni dell'immagine
  const toggleImageOptions = () => {
    setShowImageOptions(!showImageOptions);
  };

  if (loading) {
    return <div className="text-center py-8">Caricamento dati utente...</div>;
  }

  return (
    <div className="space-y-6">
      {/* Dati Personali Title */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t("account_personal_data")}</h2>
      </div>

      {/* Personal Details Card */}
      <Card className="bg-white">
        <CardContent>
          <form onSubmit={handlePersonalDetailsSubmit} className="space-y-6">
            {/* Image Upload Section */}
            <div className="flex items-center gap-4 pb-6 border-b">
              <div className="relative">
                <Avatar
                  className="w-16 h-16 bg-primary cursor-pointer"
                  onClick={toggleImageOptions}
                >
                  <AvatarImage
                    key={
                      user?.avatar_url ||
                      user?.identities[0].identity_data.avatar_url
                    }
                    src={
                      user?.avatar_url ||
                      user?.identities[0].identity_data.avatar_url
                    }
                    alt={
                      `${personalDetails.first_name} ${personalDetails.last_name}` ||
                      user?.identities[0].identity_data.full_name
                    }
                  />
                  <AvatarFallback className="bg-primary text-primary-foreground text-lg font-medium uppercase">
                    {personalDetails.first_name?.[0]}
                    {personalDetails.last_name?.[0]}
                  </AvatarFallback>
                </Avatar>

                {/* Menu opzioni immagine */}
                {showImageOptions && (
                  <div className="absolute top-full left-0 mt-2 bg-white shadow-lg rounded-md p-2 z-10 w-48">
                    <div className="text-sm font-medium mb-2 px-2">
                      Opzioni immagine
                    </div>

                    <label
                      htmlFor="image-upload"
                      className="flex items-center gap-2 px-2 py-1.5 hover:bg-gray-100 rounded cursor-pointer"
                    >
                      <RefreshCw size={16} />
                      <span>Cambia immagine</span>
                    </label>

                    <button
                      type="button"
                      onClick={handleRemoveImage}
                      disabled={isRemoving || !user?.avatar_url}
                      className={`flex items-center gap-2 px-2 py-1.5 hover:bg-gray-100 rounded w-full text-left ${
                        isRemoving || !user?.avatar_url
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    >
                      <X size={16} />
                      <span>Rimuovi immagine</span>
                    </button>
                  </div>
                )}
              </div>

              <div>
                <input
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <label
                  htmlFor="image-upload"
                  className={`
                    inline-flex items-center gap-2 px-4 py-2 rounded-md
                    bg-primary text-white cursor-pointer text-sm
                    hover:bg-primary/90 transition-colors
                    ${isUploading ? "opacity-50 cursor-not-allowed" : ""}
                  `}
                >
                  {isUploading ? t("uploading") : t("add_photo")}
                </label>
                <p className="text-xs text-gray-500 mt-1">
                  {t("supported_formats")}: JPG, PNG, GIF (max. 5MB)
                </p>
              </div>
            </div>

            <div className="grid gap-4 md:grid-cols-2">
              <div>
                <Label htmlFor="first_name">{t("account_first_name")}</Label>
                <Input
                  id="first_name"
                  value={personalDetails.first_name}
                  onChange={(e) =>
                    handleInputChange("first_name", e.target.value)
                  }
                  placeholder={t("account_first_name")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="last_name">{t("account_last_name")}</Label>
                <Input
                  id="last_name"
                  value={personalDetails.last_name}
                  onChange={(e) =>
                    handleInputChange("last_name", e.target.value)
                  }
                  placeholder={t("account_last_name")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="email">{t("account_email")}</Label>
                <Input
                  id="email"
                  type="email"
                  value={personalDetails.email}
                  disabled
                  className="h-8 bg-gray-50"
                />
              </div>

              <div>
                <Label htmlFor="phone">{t("account_phone")}</Label>
                <Input
                  id="phone"
                  value={personalDetails.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                  placeholder={t("account_phone")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="tax_id">{t("account_tax_id")}</Label>
                <Input
                  id="tax_id"
                  value={personalDetails.tax_id}
                  onChange={(e) => handleInputChange("tax_id", e.target.value)}
                  placeholder={t("account_tax_id")}
                  className="h-8"
                />
              </div>

              {/* Address Section */}
              <div className="md:col-span-2">
                <Label htmlFor="address1">{t("account_address1")}</Label>
                <Input
                  id="address1"
                  value={personalDetails.address1}
                  onChange={(e) =>
                    handleInputChange("address1", e.target.value)
                  }
                  placeholder={t("account_address1")}
                  className="h-8"
                />
              </div>

              <div className="md:col-span-2">
                <Label htmlFor="address2">{t("account_address2")}</Label>
                <Input
                  id="address2"
                  value={personalDetails.address2}
                  onChange={(e) =>
                    handleInputChange("address2", e.target.value)
                  }
                  placeholder={t("account_address2")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="city">{t("account_city")}</Label>
                <Input
                  id="city"
                  value={personalDetails.city}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  placeholder={t("account_city")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="state">{t("account_province")}</Label>
                <Input
                  id="state"
                  value={personalDetails.state}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  placeholder={t("account_province")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="country">{t("account_country")}</Label>
                <Input
                  id="country"
                  value={personalDetails.country}
                  onChange={(e) => handleInputChange("country", e.target.value)}
                  placeholder={t("account_country")}
                  className="h-8"
                />
              </div>

              <div>
                <Label htmlFor="zip_code">{t("account_zip")}</Label>
                <Input
                  id="zip_code"
                  value={personalDetails.zip_code}
                  onChange={(e) =>
                    handleInputChange("zip_code", e.target.value)
                  }
                  placeholder={t("account_zip")}
                  className="h-8"
                />
              </div>
            </div>

            <div className="pt-4 border-t">
              <Button type="submit" className="w-full" disabled={!isModified}>
                {t("account_save_changes")}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountDetails;
