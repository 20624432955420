import React from "react";
import { Sparkles, CreditCard, Smartphone, Book, Check } from "lucide-react";
import { Card, CardContent } from "../../components/ui/card";

const MonetizeSection = () => {
  return (
    <section id="monetize" className="py-20 bg-white scroll-mt-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Aumenta i Tuoi Ricavi
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Sfrutta al massimo ogni prenotazione con servizi aggiuntivi e
            strumenti di monetizzazione
          </p>
        </div>

        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            {/* Immagine illustrativa */}
            <div className="flex items-center justify-center order-2 md:order-1">
              <div className="relative">
                <div className="absolute -inset-4 bg-primary/5 rounded-xl -z-10"></div>
                <img
                  src="/monetize-dashboard.png"
                  alt="Dashboard di monetizzazione"
                  className="rounded-lg shadow-lg border border-gray-200"
                />
              </div>
            </div>

            {/* Colonna Monetizza */}
            <div className="order-1 md:order-2">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-8 flex items-center gap-2">
                <span className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center text-primary">
                  2
                </span>
                Monetizza
              </h2>
              <div className="space-y-6">
                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Sparkles className="h-5 w-5 text-primary" />
                      Esperienze
                    </h3>
                    <p className="text-gray-600">
                      Offri e vendi esperienze locali, tour, attività e servizi
                      aggiuntivi direttamente ai tuoi ospiti.
                    </p>
                    <ul className="mt-4 space-y-2">
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Crea pacchetti personalizzati
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Collabora con fornitori locali
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Guadagna commissioni sulle prenotazioni
                        </span>
                      </li>
                    </ul>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <CreditCard className="h-5 w-5 text-primary" />
                      Pagamenti
                    </h3>
                    <p className="text-gray-600">
                      Accetta pagamenti online in modo sicuro, gestisci
                      prenotazioni anticipate e servizi aggiuntivi.
                    </p>
                    <ul className="mt-4 space-y-2">
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Pagamenti sicuri con Stripe
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Fatturazione automatica
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Gestione rimborsi e depositi
                        </span>
                      </li>
                    </ul>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Smartphone className="h-5 w-5 text-primary" />
                      Guest App personalizzabile
                    </h3>
                    <p className="text-gray-600">
                      Offri un'app mobile brandizzata per i tuoi ospiti con
                      tutte le informazioni e i servizi necessari.
                    </p>
                    <ul className="mt-4 space-y-2">
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Personalizzabile con il tuo brand
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Notifiche push per comunicazioni
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Prenotazione servizi in-app
                        </span>
                      </li>
                    </ul>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Book className="h-5 w-5 text-primary" />
                      Guida Digitale
                    </h3>
                    <p className="text-gray-600">
                      Crea guide digitali interattive per i tuoi ospiti con
                      informazioni sulla struttura e consigli locali.
                    </p>
                    <ul className="mt-4 space-y-2">
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Istruzioni per check-in/check-out
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Consigli su ristoranti e attrazioni
                        </span>
                      </li>
                      <li className="flex items-start">
                        <Check className="h-5 w-5 text-primary mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Promozione di servizi a pagamento
                        </span>
                      </li>
                    </ul>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MonetizeSection;
