import { useState, useEffect, useCallback } from "react";
import { apartmentService } from "../services/apartmentService";

/**
 * Hook personalizzato per gestire gli appartamenti
 */
export const useApartments = () => {
  const [apartments, setApartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Funzione per caricare gli appartamenti
  const fetchApartments = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { data, error } = await apartmentService.getUserApartments();

      if (error) throw error;

      setApartments(data || []);
    } catch (err) {
      console.error("Error fetching apartments:", err);
      setError(err.message || "Error fetching apartments");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Carica gli appartamenti all'avvio
  useEffect(() => {
    fetchApartments();
  }, [fetchApartments]);

  // Funzione per creare un nuovo appartamento
  const createApartment = async (apartmentData) => {
    try {
      const { data, error } =
        await apartmentService.createApartment(apartmentData);

      if (error) throw error;

      // Aggiorna la lista locale degli appartamenti
      setApartments((prev) => [data, ...prev]);

      return { success: true, data };
    } catch (err) {
      console.error("Error creating apartment:", err);
      return {
        success: false,
        error: err.message || "Error creating apartment",
      };
    }
  };

  // Funzione per aggiornare un appartamento
  const updateApartment = async (apartmentId, apartmentData) => {
    try {
      const { data, error } = await apartmentService.updateApartment(
        apartmentId,
        apartmentData,
      );

      if (error) throw error;

      // Aggiorna la lista locale degli appartamenti
      setApartments((prev) =>
        prev.map((apt) => (apt.id === apartmentId ? data : apt)),
      );

      return { success: true, data };
    } catch (err) {
      console.error("Error updating apartment:", err);
      return {
        success: false,
        error: err.message || "Error updating apartment",
      };
    }
  };

  // Funzione per eliminare un appartamento
  const deleteApartment = async (apartmentId) => {
    try {
      const { success, error } =
        await apartmentService.deleteApartment(apartmentId);

      if (error) throw error;

      if (success) {
        // Rimuovi l'appartamento dalla lista locale
        setApartments((prev) => prev.filter((apt) => apt.id !== apartmentId));
      }

      return { success };
    } catch (err) {
      console.error("Error deleting apartment:", err);
      return {
        success: false,
        error: err.message || "Error deleting apartment",
      };
    }
  };

  return {
    apartments,
    isLoading,
    error,
    refetch: fetchApartments,
    createApartment,
    updateApartment,
    deleteApartment,
  };
};
