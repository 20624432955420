import {
  Book,
  Building,
  Calendar as CalendarIcon,
  ChevronDown,
  ChevronRight,
  HelpCircle,
  Home,
  List,
  Moon,
  Settings as SettingsIcon,
  Sun,
  Users,
  Shield,
  Lock,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../contexts/ThemeContext";
import { cn } from "../../lib/utils";
import LanguageSelector from "../LanguageSelector";

const SidebarComponent = ({
  activeSection,
  setActiveSection,
  isAdmin,
  className,
  accessibleSections,
  hasApartments = false,
}) => {
  const { t } = useTranslation();
  const { isDarkMode, toggleTheme } = useTheme();
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const menuItems = [
    { id: "home", label: t("menu_home"), icon: Home },
    { id: "reservations", label: t("menu_reservations"), icon: List },
    {
      id: "apartments",
      label: t("menu_apartments"),
      icon: Building,
      subItems: [
        { id: "apartments", label: t("menu_apartments") },
        { id: "altro", label: "Altro sottomenu" },
      ],
    },
    { id: "guides", label: t("menu_guides"), icon: Book },
    { id: "calendar", label: t("menu_calendar"), icon: CalendarIcon },
    {
      id: "settings",
      label: t("menu_settings"),
      icon: SettingsIcon,
    },
    { id: "regulations", label: t("menu_regulations"), icon: Shield },
  ];

  const adminMenuItems = [
    { id: "users", label: t("menu_users"), icon: Users },
    { id: "help", label: t("menu_help_articles"), icon: HelpCircle },
  ];

  const handleMenuClick = (item) => {
    if (!accessibleSections || accessibleSections[item.id] === false) {
      return;
    }

    if (item.subItems) {
      setOpenSubmenu(openSubmenu === item.id ? null : item.id);
    } else {
      setActiveSection(item.id);
      setOpenSubmenu(null);
    }
  };

  return (
    <div
      className={cn(
        "h-full w-64 flex-shrink-0 flex flex-col border-r",
        isDarkMode
          ? "bg-gray-900 text-white border-gray-800"
          : "bg-white text-gray-900 border-gray-200",
      )}
    >
      {/* Header */}
      <div
        className={cn(
          "flex items-center p-4 border-b",
          isDarkMode ? "border-gray-800" : "border-gray-200",
        )}
      >
        <div className="flex items-center justify-center h-10 w-10 rounded-lg bg-blue-600 text-white">
          <Building className="h-5 w-5" />
        </div>
        <div className="ml-3 flex flex-col">
          <span className="font-semibold text-base">GuestSuite</span>
          <span
            className={cn(
              "text-xs",
              isDarkMode ? "text-gray-400" : "text-gray-500",
            )}
          >
            Property Management
          </span>
        </div>
        {isMobile && (
          <button
            onClick={toggleTheme}
            className="ml-auto p-2 rounded-full"
            aria-label="Toggle theme"
          >
            {isDarkMode ? (
              <Sun size={20} className="text-white" />
            ) : (
              <Moon size={20} className="text-gray-900" />
            )}
          </button>
        )}
      </div>

      {/* Main Section */}
      <div className="flex-1 overflow-y-auto">
        <div className="py-2">
          <p
            className={cn(
              "px-4 py-2 text-xs font-medium uppercase",
              isDarkMode ? "text-gray-400" : "text-gray-500",
            )}
          >
            Main
          </p>
          <ul className="space-y-1 px-2">
            {menuItems.map((item) => {
              const Icon = item.icon;
              const isSubmenuOpen = openSubmenu === item.id;
              const isActive = activeSection === item.id;
              const isAccessible =
                accessibleSections && accessibleSections[item.id] === true;

              return (
                <li key={item.id} className="w-full">
                  <button
                    onClick={() => handleMenuClick(item)}
                    disabled={!isAccessible}
                    className={cn(
                      "w-full flex items-center gap-3 px-3 py-2 rounded-md text-sm font-medium transition-colors",
                      isActive
                        ? isDarkMode
                          ? "bg-blue-600 text-white"
                          : "bg-blue-500 text-white"
                        : isDarkMode
                          ? "text-gray-200 hover:bg-gray-800"
                          : "text-gray-700 hover:bg-gray-100",
                      !isAccessible && "opacity-50 cursor-not-allowed",
                    )}
                  >
                    <Icon
                      className={cn(
                        "h-5 w-5 flex-shrink-0",
                        isActive
                          ? "text-white"
                          : isDarkMode
                            ? "text-gray-400"
                            : "text-gray-500",
                      )}
                    />
                    <span className="truncate">{item.label}</span>

                    {!isAccessible && (
                      <Lock
                        size={14}
                        className="text-amber-400 ml-1 flex-shrink-0"
                      />
                    )}

                    {item.subItems && (
                      <div className="ml-auto">
                        {isSubmenuOpen ? (
                          <ChevronDown size={16} />
                        ) : (
                          <ChevronRight size={16} />
                        )}
                      </div>
                    )}
                  </button>

                  {isSubmenuOpen && item.subItems && (
                    <ul className="mt-1 ml-6 pl-3 border-l-2 space-y-1 border-gray-200 dark:border-gray-700">
                      {item.subItems.map((subItem) => {
                        const isSubActive = activeSection === subItem.id;
                        const isSubItemAccessible =
                          accessibleSections &&
                          accessibleSections[subItem.id] === true;

                        return (
                          <li key={subItem.id}>
                            <button
                              onClick={() =>
                                isSubItemAccessible &&
                                setActiveSection(subItem.id)
                              }
                              disabled={!isSubItemAccessible}
                              className={cn(
                                "w-full flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors",
                                isSubActive
                                  ? isDarkMode
                                    ? "bg-blue-600 text-white"
                                    : "bg-blue-500 text-white"
                                  : isDarkMode
                                    ? "text-gray-200 hover:bg-gray-800"
                                    : "text-gray-700 hover:bg-gray-100",
                                !isSubItemAccessible &&
                                  "opacity-50 cursor-not-allowed",
                              )}
                            >
                              <span className="truncate">{subItem.label}</span>
                              {!isSubItemAccessible && (
                                <Lock
                                  size={14}
                                  className="text-amber-400 ml-1 flex-shrink-0"
                                />
                              )}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        {isAdmin && (
          <div className="py-2 mt-2">
            <p
              className={cn(
                "px-4 py-2 text-xs font-medium uppercase",
                isDarkMode ? "text-gray-400" : "text-gray-500",
              )}
            >
              {t("admin_only_section")}
            </p>
            <ul className="space-y-1 px-2">
              {adminMenuItems.map((item) => {
                const Icon = item.icon;
                const isActive = activeSection === item.id;
                const isAccessible =
                  accessibleSections && accessibleSections[item.id] === true;

                return (
                  <li key={item.id}>
                    <button
                      onClick={() => isAccessible && handleMenuClick(item)}
                      disabled={!isAccessible}
                      className={cn(
                        "w-full flex items-center gap-3 px-3 py-2 rounded-md text-sm font-medium transition-colors",
                        isActive
                          ? isDarkMode
                            ? "bg-blue-600 text-white"
                            : "bg-blue-500 text-white"
                          : isDarkMode
                            ? "text-gray-200 hover:bg-gray-800"
                            : "text-gray-700 hover:bg-gray-100",
                        !isAccessible && "opacity-50 cursor-not-allowed",
                      )}
                    >
                      <Icon
                        className={cn(
                          "h-5 w-5 flex-shrink-0",
                          isActive
                            ? "text-white"
                            : isDarkMode
                              ? "text-gray-400"
                              : "text-gray-500",
                        )}
                      />
                      <span className="truncate">{item.label}</span>
                      {!isAccessible && (
                        <Lock
                          size={14}
                          className="text-amber-400 ml-1 flex-shrink-0"
                        />
                      )}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      {/* Footer */}
      {isMobile && (
        <div
          className={cn(
            "p-2 border-t",
            isDarkMode ? "border-gray-800" : "border-gray-200",
          )}
        >
          <button
            onClick={() => window.open("https://help.guestsuite.app", "_blank")}
            className={cn(
              "w-full flex items-center gap-3 px-3 py-2 rounded-md text-sm font-medium transition-colors",
              isDarkMode
                ? "text-gray-200 hover:bg-gray-800"
                : "text-gray-700 hover:bg-gray-100",
            )}
          >
            <HelpCircle
              className={cn(
                "h-5 w-5 flex-shrink-0",
                isDarkMode ? "text-gray-400" : "text-gray-500",
              )}
            />
            <span>Help</span>
          </button>
          <div className="px-3 py-2">
            <LanguageSelector />
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarComponent;
