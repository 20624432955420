import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../ui/card";
import { Button } from "../ui/button";
import { Mail, Loader2 } from "lucide-react";
import { supabase } from "../../lib/supabase";

const PaymentSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const sessionId = searchParams.get("session_id");
        if (!sessionId) {
          navigate("/");
          return;
        }

        // Verifica lo stato della sessione di checkout
        const response = await fetch("/api/verify-checkout-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId }),
        });

        const data = await response.json();

        if (!data.success) {
          throw new Error(data.error || "Sessione non valida");
        }

        // Se l'utente non è autenticato, mostra il form di login
        const {
          data: { session },
        } = await supabase.auth.getSession();
        if (!session) {
          // Salva il sessionId in localStorage per recuperarlo dopo il login
          localStorage.setItem("checkoutSessionId", sessionId);
        } else {
          // Se l'utente è già autenticato, reindirizza alla dashboard
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Errore:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, [searchParams, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <Card className="max-w-md w-full">
          <CardHeader>
            <CardTitle className="text-2xl text-center text-red-600">
              Si è verificato un errore
            </CardTitle>
            <CardDescription className="text-center">{error}</CardDescription>
          </CardHeader>
          <CardContent>
            <Button className="w-full" onClick={() => navigate("/")}>
              Torna alla home
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="max-w-md w-full">
        <CardHeader>
          <CardTitle className="text-2xl text-center">
            Grazie per la tua iscrizione!
          </CardTitle>
          <CardDescription className="text-center">
            Ti abbiamo inviato un'email con il link di accesso
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="bg-blue-50 p-4 rounded-lg flex items-start space-x-4">
            <Mail className="h-6 w-6 text-blue-500 mt-1" />
            <div>
              <h3 className="font-medium text-blue-900">
                Controlla la tua email
              </h3>
              <p className="text-blue-700 text-sm mt-1">
                Abbiamo inviato un link di accesso sicuro alla tua email. Clicca
                sul link per accedere al tuo account.
              </p>
            </div>
          </div>

          <div className="text-sm text-gray-500 text-center">
            Non hai ricevuto l'email? Controlla la cartella spam o
            <Button
              variant="link"
              className="text-primary"
              onClick={() => navigate("/login")}
            >
              richiedi un nuovo link
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentSuccessPage;
