import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { showToast } from "../../utils/toast";

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Funzione per gestire il callback di autenticazione
    const handleAuthCallback = async () => {
      try {
        console.log("AuthCallback: Iniziando il processo di autenticazione");

        // Forza un reindirizzamento immediato alla dashboard
        // Questo è un approccio più diretto che potrebbe funzionare anche se ci sono problemi con Supabase
        console.log("AuthCallback: Reindirizzamento forzato alla dashboard");

        // Usa setTimeout per assicurarsi che il reindirizzamento avvenga dopo il rendering
        setTimeout(() => {
          navigate("/dashboard", { replace: true });
        }, 500);

        // Tenta comunque di verificare la sessione per completezza
        const { data, error } = await supabase.auth.getSession();

        if (error) {
          console.error(
            "AuthCallback: Errore nel recupero della sessione",
            error,
          );
        } else if (data && data.session) {
          console.log(
            "AuthCallback: Sessione valida trovata",
            data.session.user.id,
          );
          showToast.success("Accesso effettuato con successo");
        } else {
          console.log("AuthCallback: Nessuna sessione trovata");
        }
      } catch (error) {
        console.error("AuthCallback: Errore durante il callback", error);

        // Anche in caso di errore, tenta comunque di reindirizzare alla dashboard
        setTimeout(() => {
          navigate("/dashboard", { replace: true });
        }, 500);
      }
    };

    // Esegui la funzione immediatamente
    handleAuthCallback();

    // Imposta un timer di fallback per assicurarsi che il reindirizzamento avvenga comunque
    const fallbackTimer = setTimeout(() => {
      console.log(
        "AuthCallback: Timer di fallback attivato, reindirizzamento forzato",
      );
      navigate("/dashboard", { replace: true });
    }, 3000);

    // Pulisci il timer quando il componente viene smontato
    return () => clearTimeout(fallbackTimer);
  }, [navigate]);

  // Mostra un indicatore di caricamento
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      <p className="mt-4 text-gray-600">
        Completamento dell'accesso in corso...
      </p>
      <p className="mt-2 text-gray-500">Reindirizzamento alla dashboard...</p>
    </div>
  );
};

export default AuthCallback;
