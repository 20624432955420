import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Search, Menu, X } from "lucide-react";
import { Input } from "../ui/input";
import { ScrollArea } from "../ui/scroll-area";
import { cn } from "../../lib/utils";
import { useApi } from "../../hooks/useApi";
import { Button } from "../ui/button";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";

const Help = () => {
  const { t } = useTranslation();
  const [activeDoc, setActiveDoc] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sections, setSections] = useState([]);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const { apiCall } = useApi();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    loadSections();
  }, []);

  const loadSections = async () => {
    try {
      const response = await apiCall("GET", "/help/public/getAll", null, false);
      console.log(response);
      if (response.data.success) {
        // Organizza i dati in una struttura gerarchica
        const organizedData = response.data.help_sections.reduce(
          (acc, item) => {
            if (item.type === "section") {
              acc.push({
                ...item,
                items: [],
              });
            } else if (item.type === "subsection" && item.section_id) {
              const parentSection = acc.find(
                (section) => section.id === item.section_id,
              );
              if (parentSection) {
                parentSection.items = parentSection.items || [];
                parentSection.items.push(item);
              }
            }
            return acc;
          },
          [],
        );

        setSections(organizedData);
        setLoading(false);
      }
    } catch (error) {
      console.error("Load error:", error);
      setLoading(false);
    }
  };

  const handleSelect = async (item) => {
    setActiveDoc(item.id);

    try {
      // Ottieni tutti i dati per cercare l'articolo
      const response = await apiCall(
        "GET",
        "/help/public/getAll",
        null,
        false,
        false,
        false,
      );
      if (response.data.success) {
        // Cerca l'articolo corrispondente
        const article = response.data.help_sections.find(
          (data) => data.type === "article" && data.section_id === item.id,
        );

        // Se trova l'articolo, mostra il suo contenuto
        if (article) {
          setContent(article.content);
        } else {
          // Se non trova l'articolo, mostra il contenuto dell'item stesso
          setContent(item.content);
        }
      }
    } catch (error) {
      console.error("Error loading article:", error);
      setContent(null);
    }
  };

  // Filtra le sezioni in base alla ricerca
  const filteredSections = sections
    .map((section) => ({
      ...section,
      items: section.items?.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    }))
    .filter(
      (section) =>
        section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        section.items?.length > 0,
    );

  const SidebarContent = () => (
    <div className="space-y-6">
      {loading ? (
        <div className="text-sm text-muted-foreground">
          {t("common_loading")}
        </div>
      ) : (
        filteredSections.map((section) => (
          <div key={section.id} className="space-y-3">
            <button
              onClick={() => {
                handleSelect(section);
                setIsOpen(false);
              }}
              className={cn(
                "w-full text-left font-semibold text-base px-3 py-2.5 rounded-md flex items-center",
                activeDoc === section.id
                  ? "bg-primary text-primary-foreground"
                  : "hover:bg-accent hover:text-accent-foreground",
              )}
            >
              {section.title}
            </button>
            <div className="space-y-1 ml-3">
              {section.items?.map((item) => (
                <button
                  key={item.id}
                  onClick={() => {
                    handleSelect(item);
                    setIsOpen(false);
                  }}
                  className={cn(
                    "block w-full text-left px-3 py-2.5 text-base rounded-md flex items-center",
                    activeDoc === item.id
                      ? "bg-primary text-primary-foreground"
                      : "text-muted-foreground hover:bg-accent hover:text-accent-foreground",
                  )}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );

  return (
    <div className="flex min-h-screen bg-background">
      {/* Mobile Menu Button */}
      <div className="md:hidden fixed top-4 left-4 z-50">
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="flex items-center justify-center w-10 h-10 bg-background shadow-md"
            >
              <Menu className="h-5 w-5" />
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="w-[85vw] max-w-sm p-0">
            <div className="flex flex-col h-full">
              <div className="p-4 border-b">
                <div className="relative">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input
                    placeholder={t("help_search_placeholder")}
                    className="pl-8"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <ScrollArea className="flex-1 p-4">
                <SidebarContent />
              </ScrollArea>
            </div>
          </SheetContent>
        </Sheet>
      </div>

      {/* Desktop Sidebar */}
      <div className="hidden md:block w-64 border-r bg-gray-50/40 dark:bg-gray-900/40">
        <div className="p-4 border-b">
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder={t("help_search_placeholder")}
              className="pl-8"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <ScrollArea className="h-[calc(100vh-5rem)]">
          <div className="p-4">
            <SidebarContent />
          </div>
        </ScrollArea>
      </div>

      {/* Main Content */}
      <div className="flex-1">
        <ScrollArea className="h-screen">
          <div className="max-w-3xl mx-auto py-8 md:py-12 px-4 md:px-8 mt-16 md:mt-0">
            {content ? (
              <>
                <div className="mb-8">
                  <h1 className="text-xl md:text-2xl font-semibold text-foreground">
                    {activeDoc &&
                      filteredSections
                        .map((section) =>
                          section.id === activeDoc
                            ? section.title
                            : section.items?.find(
                                (item) => item.id === activeDoc,
                              )?.title,
                        )
                        .filter(Boolean)[0]}
                  </h1>
                </div>
                <article
                  className={cn(
                    "prose dark:prose-invert max-w-none",
                    "prose-headings:font-semibold prose-headings:text-foreground",
                    "prose-p:text-muted-foreground prose-p:leading-relaxed",
                    "prose-strong:text-foreground prose-strong:font-semibold",
                    "prose-a:text-primary hover:prose-a:text-primary/80",
                    "prose-code:text-muted-foreground prose-code:bg-muted prose-code:rounded prose-code:px-1",
                    "prose-pre:bg-muted prose-pre:text-muted-foreground",
                    "prose-blockquote:text-muted-foreground prose-blockquote:border-l-primary",
                    "prose-li:text-muted-foreground",
                    "prose-table:text-muted-foreground",
                    "prose-hr:border-border",
                    "prose-sm md:prose-base",
                  )}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="space-y-4"
                  />
                </article>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center min-h-[60vh] mt-8 md:mt-0">
                <div className="text-center text-muted-foreground">
                  {t("help_select_topic")}
                </div>
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

export default Help;
