// client/src/components/auth/ProtectedRoute.js
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, loading, checkAuth } = useAuth();
  const location = useLocation();

  console.log("ProtectedRoute - Current location:", location);
  console.log("ProtectedRoute - User state:", { user, loading });
  console.log("ProtectedRoute - checkAuth function:", typeof checkAuth);

  useEffect(() => {
    console.log("ProtectedRoute - useEffect triggered");
    if (!user && typeof checkAuth === "function") {
      console.log("ProtectedRoute - Checking auth because user is null");
      try {
        checkAuth();
      } catch (error) {
        console.error("ProtectedRoute - Error checking auth:", error);
      }
    }
  }, [user, checkAuth]);

  if (loading) {
    console.log("ProtectedRoute - Still loading...");
    return (
      <div className="flex justify-center items-center h-full">
        <div className="loader"></div>
      </div>
    );
  }

  if (!user) {
    console.log("ProtectedRoute - No user, redirecting to login");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  console.log("ProtectedRoute - User authenticated, rendering children");
  return children;
};

// Non è più necessario il wrapper con AuthProvider poiché è già presente in App.js
export default ProtectedRoute;
