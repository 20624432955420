import { Check, ArrowRight, Star, ChevronRight, Loader2 } from "lucide-react";
import { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import Footer from "../common/Footer";
import Navbar from "./Navbar";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";

import { showToast } from "../../utils/toast";
import { supabase } from "../../lib/supabase";
import FeaturesSection from "./FeaturesSection";
import IntegrationsSection from "./IntegrationsSection";
import MonetizeSection from "./MonetizeSection";

// Inizializza Stripe
// Usa una chiave hardcoded temporaneamente per il debug
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY,
);

// Definizione dei benefits all'inizio del file
const benefits = [
  {
    icon: "🚀",
    title: "Veloce da Configurare",
    description: "Setup in 5 minuti. Nessuna competenza tecnica richiesta.",
  },
  {
    icon: "💅",
    title: "Design Professionale",
    description:
      "Template moderni e responsive, ottimizzati per le conversioni.",
  },
  {
    icon: "📱",
    title: "Tutto in Uno",
    description:
      "Sito web, gestione prenotazioni e guide digitali in un'unica piattaforma.",
  },
];

// Dati testimonial
const testimonials = [
  {
    name: "Marco Rossi",
    role: "Proprietario B&B Villa Bella",
    image: "/testimonial1.jpg", // Aggiungi le immagini nella cartella public
    quote:
      "Ho creato il sito del mio B&B in meno di 10 minuti. Le prenotazioni sono aumentate del 40% nel primo mese!",
    rating: 5,
  },
  {
    name: "Laura Bianchi",
    role: "Host Appartamenti Milano",
    image: "/testimonial2.jpg",
    quote:
      "GuestSuite mi ha permesso di gestire tutti i miei appartamenti da un'unica dashboard. Un vero game changer!",
    rating: 5,
  },
  {
    name: "Giuseppe Verdi",
    role: "Proprietario Casa Vacanze",
    image: "/testimonial3.jpg",
    quote:
      "Le guide digitali sono fantastiche. I miei ospiti hanno tutto ciò che serve a portata di smartphone.",
    rating: 5,
  },
];

// Dati FAQ
const faqs = [
  {
    question: "Quanto tempo serve per creare il mio sito?",
    answer:
      "Con GuestSuite puoi creare il tuo sito professionale in meno di 5 minuti. Basta inserire le informazioni base dei tuoi appartamenti e le foto, al resto pensiamo noi.",
  },
  {
    question: "Posso usare un dominio personalizzato?",
    answer:
      "Sì, puoi utilizzare il tuo dominio personalizzato o ottenere un sottodominio gratuito nomestruttura.guestsuite.app",
  },
  {
    question: "Come funziona la prova gratuita?",
    answer:
      "Puoi provare GuestSuite gratuitamente per 14 giorni, senza carta di credito. Avrai accesso a tutte le funzionalità premium e potrai decidere se continuare al termine del periodo di prova.",
  },
  {
    question: "Posso gestire più appartamenti?",
    answer:
      "Sì, puoi gestire tutti i tuoi appartamenti da un'unica dashboard. Il piano Pro ti permette di gestire fino a 5 appartamenti, mentre il piano Enterprise non ha limiti.",
  },
  {
    question: "Come funziona il supporto?",
    answer:
      "Offriamo supporto via email 7 giorni su 7. I clienti Pro hanno accesso al supporto prioritario, mentre i clienti Enterprise hanno un account manager dedicato.",
  },
];

// Aggiungi questi dati all'inizio del file
const howItWorks = [
  {
    step: "1",
    title: "Crea il Tuo Sito",
    description:
      "Scegli un nome personalizzato per il tuo sito (es: villamarina.guestsuite.app) e personalizza il tuo spazio in pochi click.",
    icon: "🎯",
  },
  {
    step: "2",
    title: "Aggiungi i Tuoi Appartamenti",
    description:
      "Carica foto, descrizioni e prezzi dei tuoi appartamenti. Personalizza ogni dettaglio per attirare più ospiti.",
    icon: "🏠",
  },
  {
    step: "3",
    title: "Crea Guide Digitali",
    description:
      "Fornisci ai tuoi ospiti guide digitali con informazioni utili, consigli e istruzioni per il check-in self service.",
    icon: "📱",
  },
  {
    step: "4",
    title: "Sincronizza i Calendari",
    description:
      "Collega i tuoi calendari di Airbnb, Booking e altri portali tramite iCal per gestire tutte le prenotazioni in un unico posto.",
    icon: "📅",
  },
  {
    step: "5",
    title: "Gestisci le Prenotazioni",
    description:
      "Tieni traccia di tutte le prenotazioni, invia automaticamente le guide agli ospiti e gestisci i check-in/out.",
    icon: "✨",
  },
];

const LandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState({});
  const [plans, setPlans] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const navigate = useNavigate();
  const location = useLocation();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [selectedPriceId, setSelectedPriceId] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setLoadingPlans(true);
        const baseUrl =
          process.env.REACT_APP_NODE_ENV === "production"
            ? "https://guestsuite.app"
            : "http://localhost:3000";

        const response = await fetch(`${baseUrl}/api/subscription/plans`);
        const data = await response.json();
        console.log("API Response:", data);

        if (data.plans && Array.isArray(data.plans)) {
          // Non riformat tiamo i dati, usiamo quelli che arrivano dall'API
          setPlans(data.plans);
        } else {
          console.error("Invalid plans format:", data);
          setPlans([]);
        }
      } catch (error) {
        console.error("Error loading plans:", error);
        setPlans([]);
      } finally {
        setLoadingPlans(false);
      }
    };

    fetchPlans();
  }, []);

  const handleCheckout = useCallback(async (planType, priceId, email) => {
    try {
      setLoadingPlans((prev) => ({ ...prev, [planType]: true }));

      if (!priceId) {
        throw new Error("Price ID non trovato");
      }

      const baseUrl =
        process.env.REACT_APP_NODE_ENV === "production"
          ? "https://guestsuite.app"
          : "http://localhost:3000";

      const response = await fetch(`${baseUrl}/api/subscription/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId,
          planId: planType,
          trialPeriodDays: 14,
          email,
          cancel_url: window.location.href,
        }),
      });

      const data = await response.json();

      if (!data.sessionId) {
        throw new Error(data.error || "Errore nella creazione della sessione");
      }

      const stripe = await stripePromise;
      if (!stripe) throw new Error("Stripe non è stato caricato correttamente");

      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error("Errore durante il processo di checkout:", error);
      showToast.error(
        error.message || "Si è verificato un errore durante il checkout",
      );
    } finally {
      setLoadingPlans((prev) => ({ ...prev, [planType]: false }));
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const checkLoginRedirect = async () => {
      if (location.state?.fromLogin && location.state?.plan) {
        await handleCheckout(
          location.state.plan,
          location.state.interval || "month",
          location.state.email || "",
        );

        navigate("/", { replace: true });
      }
    };

    checkLoginRedirect();
  }, [location, handleCheckout, navigate]);

  useEffect(() => {
    // Controlla se c'è un hash nell'URL
    if (location.hash) {
      // Rimuovi il # dall'hash
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) {
        // Aspetta un momento per assicurarsi che la pagina sia completamente caricata
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, [location]);

  const IntervalSwitch = () => {
    // Calcola lo sconto medio tra tutti i piani
    const averageDiscount = Math.round(
      plans.reduce((acc, plan) => {
        const yearlyDiscount =
          plan.monthly.amount > 0
            ? ((plan.monthly.amount * 12 - plan.yearly.amount) /
                (plan.monthly.amount * 12)) *
              100
            : 0;
        return acc + yearlyDiscount;
      }, 0) / plans.length,
    );

    return (
      <div className="flex flex-col items-center mb-8">
        <div className="bg-gray-100 p-2 rounded-lg inline-flex items-center gap-4">
          <button
            className={`px-4 py-2 rounded cursor-pointer transition-all ${
              selectedInterval === "monthly"
                ? "bg-white shadow-sm font-medium"
                : "hover:bg-white/50"
            }`}
            onClick={() => setSelectedInterval("monthly")}
          >
            Mensile
          </button>
          <button
            className={`px-4 py-2 rounded cursor-pointer transition-all flex items-center gap-2 ${
              selectedInterval === "yearly"
                ? "bg-white shadow-sm font-medium"
                : "hover:bg-white/50"
            }`}
            onClick={() => setSelectedInterval("yearly")}
          >
            Annuale
            {averageDiscount > 0 && (
              <span className="bg-green-100 text-green-800 text-xs font-medium px-2 py-1 rounded">
                Fino a -{averageDiscount}%
              </span>
            )}
          </button>
        </div>
      </div>
    );
  };

  const scrollToPricing = () => {
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEmailSubmit = async () => {
    if (!email) {
      showToast.error("Inserisci un'email valida");
      return;
    }

    setIsCheckingEmail(true);
    try {
      const { data, error } = await supabase.rpc("get_user_by_email", {
        email_input: email,
      });

      if (error) {
        console.error("Errore nella query:", error);
        showToast.error("Errore durante la verifica dell'email.");
        return;
      }

      if (data.length > 0) {
        showToast.error(
          "Email già registrata. Visita la pagina di billing o usa una nuova email.",
        );
        return;
      }

      // Procedi direttamente al checkout
      await handleCheckout(selectedPlanId, selectedPriceId, email);
      setIsEmailDialogOpen(false); // Chiudi il modale dopo il checkout
    } catch (error) {
      console.error("Errore durante la verifica dell'email:", error);
      showToast.error("Errore durante la verifica dell'email.");
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const handlePlanClick = (planId, priceId) => {
    setSelectedPlanId(planId);
    setSelectedPriceId(priceId);
    setIsEmailDialogOpen(true); // Apri il modale per l'email
  };

  return (
    <div className="min-h-screen w-full overflow-x-hidden bg-white">
      <Navbar
        className={`fixed top-0 w-full z-50 transition-all duration-300 ${isScrolled ? "bg-white/95 backdrop-blur-sm shadow-sm" : ""}`}
      />

      <section className="pt-32 pb-24 relative overflow-hidden bg-gradient-to-b from-primary/5 to-transparent">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-40 -right-40 w-80 h-80 bg-primary/10 rounded-full blur-3xl" />
          <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-secondary/10 rounded-full blur-3xl" />
        </div>

        <div className="container mx-auto px-4 relative">
          <div className="max-w-4xl mx-auto text-center">
            <div className="space-y-6">
              <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4 animate-fade-in">
                <span className="relative flex h-2 w-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-primary"></span>
                </span>
                Novità: Gestione Alloggiati Web integrata
              </div>

              <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-6 text-black bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary-dark animate-fade-in-up">
                Il Tuo B&B Online
                <br />
                in 5 Minuti
              </h1>

              <p className="text-xl sm:text-2xl text-gray-800 mb-8 leading-relaxed animate-fade-in-up delay-100">
                Smetti di perdere tempo con siti web complicati.
                <span className="font-semibold block mt-2 text-black">
                  GuestSuite crea il tuo sito professionale all'istante.
                </span>
              </p>

              <div className="space-y-4 animate-fade-in-up delay-200">
                <Button
                  size="lg"
                  className="group bg-primary hover:bg-primary-dark text-white px-12 py-6 text-xl font-medium rounded-[12px] shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200"
                  onClick={() => navigate("#pricing")}
                  disabled={
                    loadingPlans.starter ||
                    loadingPlans.pro ||
                    loadingPlans.enterprise
                  }
                >
                  {loadingPlans.starter ||
                  loadingPlans.pro ||
                  loadingPlans.enterprise ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Elaborazione...
                    </>
                  ) : (
                    <>
                      Prova Gratis per 14 giorni
                      <ArrowRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                    </>
                  )}
                </Button>
                <p className="text-sm text-gray-500 flex items-center justify-center gap-2">
                  <Check className="h-4 w-4 text-green-500" />
                  Nessuna carta di credito richiesta • Setup in 5 minuti
                </p>
              </div>

              <div className="flex flex-col sm:flex-row justify-center gap-4 mt-12 max-w-3xl mx-auto">
                <div className="glass p-4 rounded-xl text-center flex-1 bg-white/50 backdrop-blur-sm border border-gray-100 hover:border-primary/20 transition-all">
                  <div className="flex items-center justify-center text-yellow-400 text-xl gap-1">
                    <Star className="fill-yellow-400" />
                    <Star className="fill-yellow-400" />
                    <Star className="fill-yellow-400" />
                    <Star className="fill-yellow-400" />
                    <Star className="fill-yellow-400" />
                  </div>
                  <p className="text-gray-700 font-medium mt-2">
                    4.9/5 su Trustpilot
                  </p>
                </div>
                <div className="glass p-4 rounded-xl text-center flex-1 bg-white/50 backdrop-blur-sm border border-gray-100 hover:border-primary/20 transition-all">
                  <div className="text-2xl">🏆</div>
                  <p className="text-gray-700 font-medium mt-2">
                    +1000 host soddisfatti
                  </p>
                </div>
                <div className="glass p-4 rounded-xl text-center flex-1 bg-white/50 backdrop-blur-sm border border-gray-100 hover:border-primary/20 transition-all">
                  <div className="text-2xl">🎯</div>
                  <p className="text-gray-700 font-medium mt-2">
                    Supporto 24/7
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gradient-to-b from-white to-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl sm:text-5xl font-bold text-black mb-4">
              Come Funziona
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Crea il tuo sito professionale in pochi semplici passi
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {howItWorks.map((step, index) => (
              <Card
                key={index}
                className="group hover:shadow-lg transition-all duration-300 border-0 bg-white/50 backdrop-blur-sm"
              >
                <CardContent className="p-8">
                  <div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform">
                    {step.icon}
                  </div>
                  <div className="inline-flex items-center gap-2 px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
                    Step {step.step}
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-black">
                    {step.title}
                  </h3>
                  <p className="text-gray-600">{step.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-white relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,rgba(59,130,246,0.1),transparent)]" />
        <div className="container mx-auto px-4 relative">
          <div className="text-center mb-16">
            <h2 className="text-4xl sm:text-5xl font-bold text-black mb-4">
              Perché Scegliere GuestSuite?
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Tutto ciò di cui hai bisogno per gestire il tuo business
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {benefits.map((benefit, index) => (
              <Card
                key={index}
                className="group hover:shadow-lg transition-all duration-300 border border-gray-100 hover:border-primary/20"
              >
                <CardContent className="p-8">
                  <div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform">
                    {benefit.icon}
                  </div>
                  <h3 className="text-2xl font-bold mb-4 text-black group-hover:text-primary transition-colors">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <FeaturesSection />

      <IntegrationsSection />

      <MonetizeSection />

      <section
        id="pricing"
        className="py-20 scroll-mt-20 bg-gradient-to-b from-gray-50 to-white"
      >
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl sm:text-5xl font-bold mb-4 text-black">
            Scegli il Piano Perfetto per Te
          </h2>
          <p className="text-xl text-gray-600 mb-12">
            Piani flessibili che crescono con il tuo business
          </p>

          {loadingPlans.starter ||
          loadingPlans.pro ||
          loadingPlans.enterprise ? (
            <div className="flex justify-center items-center py-12">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <span className="ml-2">Caricamento piani...</span>
            </div>
          ) : plans.length > 0 ? (
            <>
              <IntervalSwitch />
              <div className="max-w-6xl mx-auto px-2 sm:px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 max-w-6xl mx-auto">
                  {plans
                    .sort((a, b) => {
                      const order = { starter: 1, pro: 2, enterprise: 3 };
                      return order[a.id] - order[b.id];
                    })
                    .map((plan) => {
                      const currentPrice =
                        selectedInterval === "monthly"
                          ? plan.monthly
                          : plan.yearly;

                      return (
                        <Card
                          key={plan.id}
                          className={`relative group transition-all duration-300 h-full cursor-pointer flex-1 ${
                            plan.id === "pro"
                              ? "border-[1.5px] border-primary hover:shadow-lg"
                              : "border border-gray-200 hover:border-primary/50 hover:shadow-lg"
                          }`}
                          onClick={() =>
                            handlePlanClick(plan.id, currentPrice.priceId)
                          }
                        >
                          <CardContent className="p-2 sm:p-4 md:p-6 flex flex-col h-full">
                            {/* Header */}
                            <div>
                              <h3 className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold mb-1 sm:mb-2 md:mb-4 text-black">
                                {plan.name}
                              </h3>
                              <div className="flex flex-col mb-1 sm:mb-2">
                                {selectedInterval === "monthly" ? (
                                  <div className="flex items-baseline">
                                    <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-black">
                                      €{currentPrice.amount}
                                    </span>
                                    <span className="text-gray-600 ml-1 text-xs sm:text-sm md:text-base">
                                      /{currentPrice.interval}
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    <div className="flex items-baseline">
                                      <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-black">
                                        €{Math.round(currentPrice.amount / 12)}
                                      </span>
                                      <span className="text-gray-600 ml-1 text-xs sm:text-sm md:text-base">
                                        /mese
                                      </span>
                                    </div>
                                    <span className="text-gray-500 text-xs sm:text-sm">
                                      addebitato annualmente (€
                                      {currentPrice.amount})
                                    </span>
                                  </>
                                )}
                              </div>

                              {/* Risparmio annuale */}
                              {selectedInterval === "yearly" &&
                                currentPrice.savings > 0 && (
                                  <div className="mb-2 md:mb-6 flex items-center gap-1 sm:gap-2">
                                    <span className="bg-green-100 text-green-800 text-xs px-1 sm:px-2 py-0.5 rounded-full font-medium">
                                      -{currentPrice.savings}%
                                    </span>
                                  </div>
                                )}
                              {(!selectedInterval === "yearly" ||
                                !currentPrice.savings > 0) && (
                                <div className="mb-2 md:mb-6" />
                              )}
                            </div>

                            {/* description - usando flex-grow per spingere il bottone in basso */}
                            <div className="flex-grow">
                              <ul className="space-y-1 sm:space-y-2 md:space-y-3">
                                {plan.description.map((feature, index) => (
                                  <li key={index} className="flex items-start">
                                    <Check className="h-3 w-3 sm:h-4 sm:w-4 md:h-5 md:w-5 text-green-500 mr-1 sm:mr-2 md:mr-3 mt-0.5 flex-shrink-0" />
                                    <span className="text-xs sm:text-sm md:text-base text-gray-700">
                                      {feature}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            {/* Button */}
                            <div className="mt-2 sm:mt-4 md:mt-6">
                              <Button
                                className="w-full py-1 sm:py-2 md:py-4 text-xs sm:text-sm md:text-base font-medium bg-primary text-white hover:bg-primary/90"
                                onClick={scrollToPricing}
                                disabled={
                                  loadingPlans.starter ||
                                  loadingPlans.pro ||
                                  loadingPlans.enterprise
                                }
                              >
                                Prova Gratis
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      );
                    })}
                </div>
              </div>
            </>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-500">
                Nessun piano disponibile al momento.
              </p>
            </div>
          )}
        </div>
      </section>

      <section
        id="testimonials"
        className="py-20 scroll-mt-20 bg-gradient-to-b from-white to-gray-50"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-black">
            Chi Usa GuestSuite
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {testimonials.map((testimonial, index) => (
              <Card
                key={index}
                className="group hover:shadow-lg transition-all duration-300"
              >
                <CardContent className="p-6">
                  <div className="flex items-center gap-4 mb-6">
                    <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200 flex-shrink-0 ring-2 ring-primary/10">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg text-black">
                        {testimonial.name}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {testimonial.role}
                      </p>
                      <div className="flex text-yellow-400 mt-1">
                        {Array.from({ length: testimonial.rating }).map(
                          (_, i) => (
                            <Star key={i} className="h-4 w-4 fill-yellow-400" />
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section id="faq" className="py-20 scroll-mt-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-black">
            Domande Frequenti
          </h2>
          <div className="max-w-3xl mx-auto">
            <Accordion type="single" collapsible className="w-full">
              {faqs.map((faq, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger className="text-left text-black hover:text-primary transition-colors">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="text-gray-600">
                    {faq.answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>

      <section className="py-20 bg-primary relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,rgba(255,255,255,0.1),transparent)]" />
        <div className="container mx-auto px-4 text-center relative">
          <h2 className="text-4xl font-bold mb-6 text-white">
            Pronto a Trasformare il Tuo Business?
          </h2>
          <p className="text-xl mb-8 text-white/90">
            Unisciti a migliaia di host che stanno già usando GuestSuite
          </p>
          <Link to="/login" className="inline-block">
            <Button
              size="lg"
              variant="secondary"
              className="group bg-white text-primary hover:bg-gray-100 px-8 py-6 text-lg font-medium"
            >
              Crea il Tuo Sito Ora
              <ChevronRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </Button>
          </Link>
        </div>
      </section>

      <div className="fixed bottom-8 right-8 z-50 md:hidden">
        <Button
          size="lg"
          className="bg-primary text-white shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-200"
          onClick={scrollToPricing}
          disabled={
            loadingPlans.starter || loadingPlans.pro || loadingPlans.enterprise
          }
        >
          Prova Gratis
          <ArrowRight className="ml-2 h-5 w-5" />
        </Button>
      </div>

      <Footer />

      <Dialog open={isEmailDialogOpen} onOpenChange={setIsEmailDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader className="text-center">
            <DialogTitle className="text-2xl font-bold text-black">
              Inizia la tua prova gratuita
            </DialogTitle>
            <DialogDescription className="text-gray-600 mt-2">
              Inserisci la tua email per iniziare il periodo di prova di 14
              giorni.
            </DialogDescription>
          </DialogHeader>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (email) handleEmailSubmit();
            }}
            className="mt-4 space-y-6"
          >
            <div className="space-y-2">
              <Input
                type="email"
                placeholder="La tua email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isCheckingEmail}
                className="h-11 text-base px-4"
                autoFocus
              />
              <p className="text-xs text-gray-500 text-center">
                Non condivideremo mai la tua email con terze parti.
              </p>
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                className="w-full sm:w-auto min-w-[200px] h-11 text-base font-medium"
                disabled={isCheckingEmail || !email}
              >
                {isCheckingEmail ? (
                  <>
                    <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                    Verifica in corso...
                  </>
                ) : (
                  "Inizia la prova gratuita"
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LandingPage;
