import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { useToast } from "../../components/ui/use-toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cn } from "../../lib/utils";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Separator } from "../../components/ui/separator";
import { Card } from "../../components/ui/card";
import {
  ChevronRight,
  ChevronDown,
  Plus,
  Save,
  FileText,
  FolderPlus,
  Pencil,
  Trash2,
  GripVertical,
} from "lucide-react";
import { Textarea } from "../../components/ui/textarea";
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "../../components/ui/alert-dialog";
import RichTextEditor from "../ui/RichTextEditor";

const CreateHelpArticle = () => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { apiCall } = useApi();

  // Stati base
  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  // Stati per la selezione e editing
  const [selectedItem, setSelectedItem] = useState(null); // { type: 'section' | 'subsection', item: {...} }
  const [editingItem, setEditingItem] = useState(null); // { type: 'section' | 'subsection', id: string, value: string }
  const [content, setContent] = useState("");

  // Aggiungi questo nuovo state
  const [rawData, setRawData] = useState([]);

  // Carica i dati iniziali

  const loadSections = async () => {
    try {
      const response = await apiCall("GET", "/help/getAll", null, true);
      console.log("API Response:", response);

      if (response.data.success) {
        const rawData = response.data.help_sections;
        console.log("Raw data:", rawData);

        // Organizziamo i dati per la visualizzazione
        const sections = rawData
          .filter((item) => item.type === "section")
          .map((section) => ({
            ...section,
            items: rawData.filter(
              (item) =>
                item.type === "subsection" && item.section_id === section.id,
            ),
          }));

        console.log("Organized sections:", sections);

        // Salviamo sia i dati raw che quelli organizzati
        setSections(sections);
        setRawData(rawData); // Nuovo state per i dati raw

        // Se c'è un elemento selezionato, trova il suo articolo
        if (selectedItem) {
          const article = rawData.find(
            (item) =>
              item.type === "article" &&
              item.section_id === selectedItem.item.id,
          );

          if (article) {
            console.log("Found article:", article);
            setContent(article.content || "");
          }
        }
      }
    } catch (error) {
      console.error("Load error:", error);
      toast({
        title: t("common_error"),
        description: t("help_error_loading_sections"),
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    loadSections();
  }, []);

  // Gestione sezioni
  const handleAddSection = async () => {
    try {
      // Trova il massimo order_num corrente
      const maxOrder = Math.max(...sections.map((s) => s.order_num || 0), -1);
      const newOrderNum = maxOrder + 1;

      const response = await apiCall(
        "POST",
        "/help/add",
        {
          title: "Nuova Sezione",
          content: "",
          type: "section",
          order_num: newOrderNum, // Aggiungi l'order_num
        },
        true,
      );

      if (response.success) {
        setSections([...sections, response.data]);
      }
    } catch (error) {
      console.error("Error creating section:", error);
      toast({
        title: t("common_error"),
        description: t("help_error_creating_section"),
        variant: "destructive",
      });
    }
  };

  // Gestione sottosezioni
  const handleAddSubsection = async (section) => {
    try {
      // Trova il massimo order_num delle sottosezioni esistenti
      const maxOrder = Math.max(
        ...(section.items?.map((s) => s.order_num || 0) || []),
        -1,
      );
      const newOrderNum = maxOrder + 1;

      const data = {
        title: "Nuova Sottosezione",
        content: "",
        type: "subsection",
        section_id: section.id,
        order_num: newOrderNum, // Questo assicura che la nuova sottosezione venga aggiunta alla fine
      };

      const response = await apiCall(
        "post",
        "/help/add",
        data, //
        true, // useAuth
        false, // usepublicToken
        false, // formData
      );

      if (response.success) {
        // Prima ricarica i dati
        await loadSections();

        // Dopo aver caricato i dati, espandi la sezione
        setTimeout(() => {
          setExpandedSections((prev) => ({
            ...prev,
            [section.id]: true,
          }));
        }, 100);
      }
    } catch (error) {
      console.error("Error creating subsection:", error);
      toast({
        title: t("common_error"),
        description: t("help_error_creating_subsection"),
        variant: "destructive",
      });
    }
  };

  // Gestione selezione e contenuto
  const handleSelect = (type, item) => {
    console.log("Selected:", { type, item });
    setSelectedItem({ type, item });

    if (type === "subsection" || type === "section") {
      console.log("All raw data:", rawData);
      console.log("Looking for section_id:", item.id);

      const articles = rawData.filter((s) => s.type === "article");
      console.log("All articles:", articles);

      const article = articles.find(
        (article) => article.section_id === item.id,
      );

      if (article) {
        console.log("Found article:", article);
        setContent(article.content || "");
      } else {
        console.log("No article found, setting empty content");
        setContent("");
      }
      setUnsavedChanges(false);
    }

    // Se è una sezione, mantieni il suo stato di espansione
    if (type === "section") {
      setExpandedSections((prev) => ({
        ...prev,
        [item.id]: true,
      }));
    }

    setEditingItem(null);
  };

  // Gestione salvataggio
  const handleSave = async () => {
    if (selectedItem) {
      try {
        console.log("Saving for:", selectedItem);
        console.log("Content to save:", content);

        const existingArticle = rawData.find(
          (item) =>
            item.type === "article" && item.section_id === selectedItem.item.id,
        );

        if (existingArticle) {
          console.log("Updating existing article:", existingArticle);
          const updateResponse = await apiCall(
            "PUT",
            "/help/update",
            {
              id: existingArticle.id,
              title: selectedItem.item.title,
              content: content,
              order_num: existingArticle.order_num,
            },
            true,
          );
          console.log("Update response:", updateResponse);
        } else {
          console.log("Creating new article");
          const addResponse = await apiCall(
            "POST",
            "/help/add",
            {
              title: selectedItem.item.title,
              content: content,
              type: "article",
              section_id: selectedItem.item.id,
              order_num: 0,
            },
            true,
          );
          console.log("Add response:", addResponse);
        }

        setUnsavedChanges(false);
        toast({
          title: t("common_success"),
          description: t("help_content_saved"),
        });
        await loadSections();
      } catch (error) {
        console.error("Save error:", error);
        toast({
          title: t("common_error"),
          description: t("help_error_saving_content"),
          variant: "destructive",
        });
      }
    }
  };

  const handleUpdate = async (type, id, title, content = null) => {
    try {
      const payload = {
        id: id,
        title: title,
      };

      // Aggiungi il content solo se viene fornito (per gli articoli)
      if (content !== null) {
        payload.content = content;
      }

      const response = await apiCall("PUT", `/help/update`, payload, true);

      if (response.success) {
        // Aggiorna localmente lo stato senza ricaricare tutto
        if (type === "section") {
          setSections((prevSections) =>
            prevSections.map((section) =>
              section.id === id ? { ...section, title } : section,
            ),
          );
        } else if (type === "subsection") {
          setSections((prevSections) =>
            prevSections.map((section) => ({
              ...section,
              items: section.items?.map((subsection) =>
                subsection.id === id ? { ...subsection, title } : subsection,
              ),
            })),
          );
        }

        toast({
          title: t("common_success"),
          description:
            type === "article"
              ? t("help_content_saved")
              : t("help_title_updated"),
        });

        // Ricarica i dati solo per gli articoli
        if (type === "article") {
          const currentExpandedState = { ...expandedSections };
          await loadSections();
          setExpandedSections(currentExpandedState);
          setUnsavedChanges(false);
        }
      }
    } catch (error) {
      console.error("Update error:", error);
      toast({
        title: t("common_error"),
        description: t("help_error_updating"),
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (type, id) => {
    try {
      const response = await apiCall(
        "DELETE",
        `/help/remove/${id}`,
        null,
        true,
      );
      console.log(response);
      if (response.success) {
        toast({
          title: t("common_success"),
          description: t("help_item_deleted"),
        });

        // Reset selection if deleted item was selected
        if (selectedItem?.item.id === id) {
          setSelectedItem(null);
          setContent("");
        }

        loadSections();
      }
    } catch (error) {
      console.error("Delete error:", error);
      toast({
        title: t("common_error"),
        description: t("help_error_deleting_item"),
        variant: "destructive",
      });
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    // Se stiamo riordinando le sezioni principali
    if (source.droppableId === "sections") {
      const items = Array.from(sections);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      // Aggiorna lo stato locale immediatamente
      setSections(items);

      try {
        // Aggiorna l'ordine di tutte le sezioni
        const updatePromises = items.map((section, index) => {
          return apiCall(
            "PUT",
            `/help/update`,
            {
              id: section.id,
              title: section.title,
              order_num: index,
            },
            true,
          );
        });

        await Promise.all(updatePromises);
        await loadSections();

        toast({
          title: t("common_success"),
          description: t("help_order_updated"),
        });
      } catch (error) {
        console.error("Order update error:", error);
        toast({
          title: t("common_error"),
          description: t("help_error_updating_order"),
          variant: "destructive",
        });
        await loadSections();
      }
    }
    // Se stiamo riordinando le sottosezioni
    else {
      const sectionId = source.droppableId;
      const section = sections.find((s) => s.id === parseInt(sectionId));
      if (!section) return;

      const items = Array.from(section.items || []);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      // Aggiorna lo stato locale immediatamente
      setSections(
        sections.map((s) => {
          if (s.id === parseInt(sectionId)) {
            return { ...s, items };
          }
          return s;
        }),
      );

      try {
        // Aggiorna l'ordine di tutte le sottosezioni
        const updatePromises = items.map((subsection, index) => {
          return apiCall(
            "PUT",
            `/help/update`,
            {
              id: subsection.id,
              title: subsection.title,
              order_num: index,
            },
            true,
          );
        });

        await Promise.all(updatePromises);
        await loadSections();

        toast({
          title: t("common_success"),
          description: t("help_order_updated"),
        });
      } catch (error) {
        console.error("Order update error:", error);
        toast({
          title: t("common_error"),
          description: t("help_error_updating_order"),
          variant: "destructive",
        });
        await loadSections();
      }
    }
  };

  return (
    <div className="flex flex-col sm:flex-row h-full bg-background gap-0">
      {/* Menu */}
      <div className="w-[360px] md:w-[300px] lg:w-[350px] p-1 sm:border-r h-[calc(100vh-12rem)] flex flex-col">
        <Button
          variant="outline"
          className="w-full flex items-center justify-center gap-1 text-xs mb-2"
          onClick={handleAddSection}
        >
          <Plus className="h-3 w-3" />
          <span className="hidden md:inline">{t("help_add_section")}</span>
          <span className="md:hidden">Add</span>
        </Button>

        <ScrollArea className="h-[calc(50vh-8rem)] sm:h-[calc(70vh-12rem)] flex-1">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.map((section, index) => (
                    <Draggable
                      key={section.id}
                      draggableId={String(section.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={cn(
                            "flex flex-col gap-1",
                            snapshot.isDragging && "opacity-50",
                          )}
                        >
                          {/* Sezione principale */}
                          <div
                            className={cn(
                              "flex items-center justify-between p-1.5 rounded-md hover:bg-accent/10 text-xs",
                              selectedItem?.type === "section" &&
                                selectedItem.item.id === section.id &&
                                "bg-accent/50",
                            )}
                            onClick={() => handleSelect("section", section)}
                          >
                            <div className="flex items-center gap-1 flex-1 min-w-0">
                              <div {...provided.dragHandleProps}>
                                <GripVertical className="h-4 w-4 text-muted-foreground" />
                              </div>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setExpandedSections((prev) => ({
                                    ...prev,
                                    [section.id]: !prev[section.id],
                                  }));
                                }}
                              >
                                {expandedSections[section.id] ? (
                                  <ChevronDown className="h-3 w-3" />
                                ) : (
                                  <ChevronRight className="h-3 w-3" />
                                )}
                              </button>
                              {editingItem?.type === "section" &&
                              editingItem.id === section.id ? (
                                <Input
                                  autoFocus
                                  defaultValue={section.title}
                                  className="h-7 text-xs w-[120px]"
                                  onBlur={(e) => {
                                    if (
                                      e.target.value.trim() !== section.title
                                    ) {
                                      handleUpdate(
                                        "section",
                                        section.id,
                                        e.target.value.trim(),
                                      );
                                    }
                                    setEditingItem(null);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      if (
                                        e.target.value.trim() !== section.title
                                      ) {
                                        handleUpdate(
                                          "section",
                                          section.id,
                                          e.target.value.trim(),
                                        );
                                      }
                                      setEditingItem(null);
                                    }
                                    if (e.key === "Escape") {
                                      setEditingItem(null);
                                    }
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              ) : (
                                <span className="truncate">
                                  {section.title}
                                </span>
                              )}
                            </div>

                            <div className="flex items-center">
                              <Button
                                variant="ghost"
                                size="icon"
                                className="h-7 w-7 p-1 touch-manipulation hover:bg-accent/20 focus-visible:ring-0 focus:ring-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddSubsection(section);
                                }}
                              >
                                <Plus className="h-4 w-4" />
                              </Button>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="h-7 w-7 p-1 touch-manipulation hover:bg-accent/20 focus-visible:ring-0 focus:ring-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingItem({
                                    type: "section",
                                    id: section.id,
                                    value: section.title,
                                  });
                                }}
                              >
                                <Pencil className="h-4 w-4" />
                              </Button>
                              <AlertDialog>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-7 w-7 p-1 touch-manipulation hover:bg-accent/20 focus-visible:ring-0 focus:ring-0"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                  <AlertDialogHeader>
                                    <AlertDialogTitle>
                                      {t("help_delete_confirm_title")}
                                    </AlertDialogTitle>
                                    <AlertDialogDescription>
                                      {t("help_delete_confirm_description")}
                                    </AlertDialogDescription>
                                  </AlertDialogHeader>
                                  <AlertDialogFooter>
                                    <AlertDialogCancel>
                                      {t("common_cancel")}
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete("section", section.id);
                                      }}
                                    >
                                      {t("common_delete")}
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialog>
                            </div>
                          </div>

                          {/* Sottosezioni in un contenitore separato */}
                          {expandedSections[section.id] && (
                            <Droppable droppableId={String(section.id)}>
                              {(providedSub) => (
                                <div
                                  ref={providedSub.innerRef}
                                  {...providedSub.droppableProps}
                                  className="ml-4 space-y-1"
                                >
                                  {section.items?.map(
                                    (subsection, subIndex) => (
                                      <Draggable
                                        key={subsection.id}
                                        draggableId={`sub-${subsection.id}`}
                                        index={subIndex}
                                      >
                                        {(providedSubItem, snapshotSub) => (
                                          <div
                                            ref={providedSubItem.innerRef}
                                            {...providedSubItem.draggableProps}
                                            {...providedSubItem.dragHandleProps}
                                            className={cn(
                                              "flex items-center justify-between p-1.5 rounded-md hover:bg-accent/10 text-xs",
                                              selectedItem?.type ===
                                                "subsection" &&
                                                selectedItem.item.id ===
                                                  subsection.id &&
                                                "bg-accent/50",
                                              snapshotSub.isDragging &&
                                                "opacity-50",
                                            )}
                                            onClick={() =>
                                              handleSelect(
                                                "subsection",
                                                subsection,
                                              )
                                            }
                                          >
                                            <div className="flex items-center gap-1 flex-1 min-w-0">
                                              <GripVertical className="h-4 w-4 text-muted-foreground" />
                                              {editingItem?.type ===
                                                "subsection" &&
                                              editingItem.id ===
                                                subsection.id ? (
                                                <Input
                                                  autoFocus
                                                  defaultValue={
                                                    subsection.title
                                                  }
                                                  className="h-7 text-xs w-[120px]"
                                                  onBlur={(e) => {
                                                    if (
                                                      e.target.value.trim() !==
                                                      subsection.title
                                                    ) {
                                                      handleUpdate(
                                                        "subsection",
                                                        subsection.id,
                                                        e.target.value.trim(),
                                                      );
                                                    }
                                                    setEditingItem(null);
                                                  }}
                                                  onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                      if (
                                                        e.target.value.trim() !==
                                                        subsection.title
                                                      ) {
                                                        handleUpdate(
                                                          "subsection",
                                                          subsection.id,
                                                          e.target.value.trim(),
                                                        );
                                                      }
                                                      setEditingItem(null);
                                                    }
                                                    if (e.key === "Escape") {
                                                      setEditingItem(null);
                                                    }
                                                  }}
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                />
                                              ) : (
                                                <span className="truncate">
                                                  {subsection.title}
                                                </span>
                                              )}
                                            </div>
                                            <div className="flex items-center">
                                              <Button
                                                variant="ghost"
                                                size="icon"
                                                className="h-7 w-7 p-1 touch-manipulation hover:bg-accent/20 focus-visible:ring-0 focus:ring-0"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setEditingItem({
                                                    type: "subsection",
                                                    id: subsection.id,
                                                    value: subsection.title,
                                                  });
                                                }}
                                              >
                                                <Pencil className="h-4 w-4" />
                                              </Button>
                                              <AlertDialog>
                                                <AlertDialogTrigger asChild>
                                                  <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    className="h-7 w-7 p-1 touch-manipulation hover:bg-accent/20 focus-visible:ring-0 focus:ring-0"
                                                    onClick={(e) =>
                                                      e.stopPropagation()
                                                    }
                                                  >
                                                    <Trash2 className="h-4 w-4" />
                                                  </Button>
                                                </AlertDialogTrigger>
                                                <AlertDialogContent>
                                                  <AlertDialogHeader>
                                                    <AlertDialogTitle>
                                                      {t(
                                                        "help_delete_confirm_title",
                                                      )}
                                                    </AlertDialogTitle>
                                                    <AlertDialogDescription>
                                                      {t(
                                                        "help_delete_confirm_description",
                                                      )}
                                                    </AlertDialogDescription>
                                                  </AlertDialogHeader>
                                                  <AlertDialogFooter>
                                                    <AlertDialogCancel>
                                                      {t("common_cancel")}
                                                    </AlertDialogCancel>
                                                    <AlertDialogAction
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(
                                                          "subsection",
                                                          subsection.id,
                                                        );
                                                      }}
                                                    >
                                                      {t("common_delete")}
                                                    </AlertDialogAction>
                                                  </AlertDialogFooter>
                                                </AlertDialogContent>
                                              </AlertDialog>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ),
                                  )}
                                  {providedSub.placeholder}
                                </div>
                              )}
                            </Droppable>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ScrollArea>

        <div className="text-xs text-muted-foreground mt-2">
          {t("help_preview")}:{" "}
          <a
            href="https://help.guestsuite.app"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:underline"
          >
            help.guestsuite.app
          </a>
        </div>
      </div>

      {/* Area contenuto */}
      <div className="flex-1 p-4 flex flex-col h-[calc(100vh-12rem)]">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <FileText className="h-5 w-5" />
            <span className="text-sm font-medium">
              {selectedItem ? selectedItem.item.title : t("help_select_item")}
            </span>
          </div>
          {selectedItem && (
            <Button
              variant="outline"
              className="flex items-center gap-1"
              onClick={handleSave}
              disabled={!unsavedChanges}
            >
              <Save className="h-4 w-4" />
              <span>{t("common_save")}</span>
            </Button>
          )}
        </div>

        {selectedItem ? (
          <Card className="flex-1">
            <RichTextEditor
              content={content}
              onChange={(newContent) => {
                console.log("Editor content changed:", newContent);
                setContent(newContent);
                setUnsavedChanges(true);
              }}
            />
          </Card>
        ) : (
          <div className="flex-1 flex items-center justify-center text-muted-foreground">
            <span>{t("help_select_to_edit")}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateHelpArticle;
