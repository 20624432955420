import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import FeatureLayout from "../../../components/layouts/FeatureLayout";
import { Button } from "../../ui/button";

const InsuranceDeposits = () => {
  const { t } = useTranslation();

  return (
    <FeatureLayout>
      <Helmet>
        <title>{t("features_insurance_deposits_title")} - GuestSuite</title>
        <meta
          name="description"
          content={t("features_insurance_deposits_description")}
        />
      </Helmet>

      <div className="min-h-screen bg-background">
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-bold mb-6">
              {t("features_insurance_deposits_title")}
            </h1>

            <div className="prose max-w-none">
              <p className="text-xl text-muted-foreground">
                {t("features_insurance_deposits_description")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </FeatureLayout>
  );
};

export default InsuranceDeposits;
