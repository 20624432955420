// components/dashboard/Dashboard.js
import { HelpCircle, Menu, Moon, Sun, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useApi } from "../../hooks/useApi";
import AdminUsersTable from "./AdminUsersTable";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import ApartmentList from "./ApartmentList";
import Calendar from "./Calendar";
import GuideList from "./GuideList";
import SidebarComponent from "./SidebarComponent";
import Welcome from "./Welcome";

import { cn } from "../../lib/utils";
import { getAvailableLanguages } from "../../utils/dataloader";
import LanguageSelector from "../LanguageSelector";
import AccountDetails from "./AccountDetails";

import Regulations from "./Regulations";
import Reservations from "./ReservationList";
import SiteSettings from "./SiteSettings";
import CreateHelpArticle from "./CreateHelpArticle";
import Logo from "../Logo";
import { CreditCard, Bell, LogOut, BadgeCheck } from "lucide-react";
import BillingDetails from "./BillingDetails";
import { useApartments } from "../../hooks/useApartments";
import { supabase } from "../../lib/supabase";
import { reservationService } from "../../services/reservationService";
import { showToast } from "../../utils/toast";
import { useSubscription } from "../../hooks/useSubscription";
import { Loader2 } from "lucide-react";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout, updateUser, loading } = useAuth();
  console.log("user", user);
  const { t, i18n } = useTranslation();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const { apiCall, isLoading: isLoadingApi, error: apiError } = useApi();
  const [guides, setGuides] = useState([]);
  const [stats, setStats] = useState({
    apartments: 0,
    guides: 0,
    reservations: 0,
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();
  const [isMobile, setIsMobile] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [helpSections, setHelpSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReservations, setIsLoadingReservations] = useState(true);
  const {
    apartments,
    isLoading: isLoadingApartments,
    error: apartmentsError,
    refetch: refetchApartments,
  } = useApartments();
  const { subscription, isLoading: isLoadingSubscription } = useSubscription();

  console.log("subscription", subscription);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Imposta la larghezza per considerare mobile
    };

    handleResize(); // Controlla la dimensione all'inizio
    window.addEventListener("resize", handleResize); // Aggiungi listener per il resize

    return () => {
      window.removeEventListener("resize", handleResize); // Pulisci il listener
    };
  }, []);

  useEffect(() => {
    getAvailableLanguages().then(setAvailableLanguages);
  }, []);

  const fetchGuides = useCallback(async () => {
    try {
      const data = await apiCall("get", "/guides", null, true);
      setGuides(data);
    } catch (error) {
      console.error("Error fetching guides:", error);
    }
  }, [apiCall]);

  const fetchReservations = useCallback(async () => {
    try {
      if (!user) {
        console.log("User not available, skipping reservation fetch");
        setReservations([]);
        return [];
      }

      console.log("Fetching reservations for user:", user.id);

      // Utilizziamo il servizio di prenotazioni invece di fare le query direttamente
      const reservationsData = await reservationService.getUserReservations();
      console.log("Processed reservations:", reservationsData);

      // Ordina le prenotazioni per data di check-in (solo se ci sono prenotazioni)
      const sortedReservations =
        reservationsData.length > 0
          ? reservationsData.sort((a, b) => {
              return new Date(a.check_in_date) - new Date(b.check_in_date);
            })
          : [];

      setReservations(sortedReservations);
      setIsLoadingReservations(false); // Assicurati di impostare lo stato di caricamento a false
      return sortedReservations;
    } catch (error) {
      console.error("Error fetching reservations:", error);
      // Non mostrare toast di errore se è solo un caso di nessuna prenotazione
      // showToast.error('Errore nel caricamento delle prenotazioni');

      // Imposta un array vuoto in caso di errore
      setReservations([]);
      setIsLoadingReservations(false); // Assicurati di impostare lo stato di caricamento a false
      return [];
    }
  }, [user]);

  const fetchHelpSections = async () => {
    try {
      const response = await apiCall("get", "/help/getAll", null, true);
      console.log("Help response", response.data);
      setHelpSections(response.data.help_sections);
    } catch (error) {
      console.error("Error fetching help sections:", error);
    }
  };

  useEffect(() => {
    // Verifica se l'utente esiste
    if (!user || !user.id) return;

    console.log(
      "Eseguo fetchData al montaggio del componente",
      new Date().toISOString(),
    );

    const fetchData = async () => {
      try {
        await Promise.all([fetchReservations()]);
      } catch (error) {
        console.error("Errore durante il recupero dei dati:", error);
      }
    };

    fetchData();
    // Assicurati che queste dipendenze siano stabili e non cambino ad ogni rendering
  }, [user, fetchReservations]);

  useEffect(() => {
    setStats({
      apartments: apartments.length,
      guides: guides.length,
      reservations: reservations.length,
    });
  }, [apartments, guides, reservations]);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();

      if (error) {
        throw error;
      }

      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const handleReservationsChange = useCallback((updatedReservations) => {
    setReservations(updatedReservations);
  }, []);

  const isSubscriptionActive = () => {
    console.log("subscription", subscription);
    if (
      subscription?.status === "active" ||
      subscription?.status === "trialing"
    ) {
      return true;
    }

    // Handle canceled subscriptions that are still active until the end date
    if (subscription?.status === "canceled" && subscription?.cancel_at) {
      // Handle cancel_at as a date string instead of Unix timestamp
      const cancelDate = new Date(subscription.cancel_at); // Direct parsing of ISO date string
      const currentDate = new Date();
      console.log("cancelDate", cancelDate, "currentDate", currentDate);
      return currentDate < cancelDate; // Still active if current date is before cancel date
    }

    return false;
  };
  const alwaysAccessibleSections = ["billing", "account", "apartments"];

  useEffect(() => {
    if (!loading) {
      if (!user) {
        console.log("Utente non autenticato, reindirizzamento al login");
        showToast.error("Accesso richiesto");
        navigate("/login", { replace: true });
      } else if (
        !isLoadingSubscription &&
        !isSubscriptionActive() &&
        !alwaysAccessibleSections.includes(getActiveSection())
      ) {
        console.log("Abbonamento scaduto o non attivo");
        console.log("sezione", getActiveSection());
        navigate("/dashboard/billing", { replace: true });
      }
    }
  }, [user, loading, subscription, isLoadingSubscription, navigate]);

  // Mostra un indicatore di caricamento mentre verifichiamo l'autenticazione
  if (loading || isLoadingSubscription) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="flex flex-col items-center gap-2">
          <Loader2 className="h-10 w-10 animate-spin text-primary" />
          <p className="mt-4 text-gray-600">{t("loading")}</p>
        </div>
      </div>
    );
  }

  // Se l'utente non è autenticato, non mostrare nulla (verrà reindirizzato)
  if (!user) {
    return null;
  }

  const getActiveSection = () => {
    const path = location.pathname.split("/").pop();
    return path === "dashboard" ? "home" : path;
  };

  const isSectionAccessible = (section) => {
    return isSubscriptionActive() || alwaysAccessibleSections.includes(section);
  };

  // Crea un oggetto che indica quali sezioni sono accessibili
  const accessibleSections = {
    home: true, // Home è sempre accessibile
    apartments: true, // Apartments è sempre accessibile
    guides: isSubscriptionActive(),
    calendar: isSubscriptionActive(),
    settings: isSubscriptionActive(),
    regulations: isSubscriptionActive(),
    reservations: isSubscriptionActive(),
    help: isSubscriptionActive(),
    account: true, // Account è sempre accessibile
    billing: true, // Billing è sempre accessibile
    users: isSubscriptionActive() && user?.role === "admin",
  };

  const renderContent = () => {
    return (
      <div className="space-y-6 flex-1">
        <Routes>
          <Route path="/" element={<Welcome user={user} stats={stats} />} />
          <Route
            path="/apartments"
            element={
              <ApartmentList
                apartments={apartments}
                isLoading={isLoadingApartments}
                error={apartmentsError}
                onRefetch={refetchApartments}
              />
            }
          />
          <Route
            path="/guides"
            element={
              <GuideList
                guides={guides}
                isLoading={isLoadingApi}
                error={apiError}
                onRefetch={fetchGuides}
              />
            }
          />
          <Route
            path="/calendar"
            element={<Calendar apartments={apartments} />}
          />
          <Route path="/settings" element={<SiteSettings user={user} />} />
          <Route
            path="/users"
            element={user.role === "admin" ? <AdminUsersTable /> : null}
          />
          <Route path="/account" element={<AccountDetails user={user} />} />
          <Route path="/billing" element={<BillingDetails user={user} />} />
          <Route
            path="/regulations"
            element={<Regulations apartments={apartments} />}
          />
          <Route
            path="/reservations"
            element={
              isLoadingReservations ? (
                <div className="flex items-center justify-center h-[calc(100vh-200px)]">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <Reservations
                  reservations={reservations}
                  apartments={apartments}
                  onReservationsChange={handleReservationsChange}
                />
              )
            }
          />
          <Route
            path="/help"
            element={
              <CreateHelpArticle
                onRefresh={fetchHelpSections}
                sections={helpSections}
              />
            }
          />
        </Routes>
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col h-screen ${
        isDarkMode ? "bg-gray-900" : "bg-background"
      }`}
    >
      {/* Header */}
      <header
        className={`p-4 flex-shrink-0 sticky top-0 z-10 ${
          isDarkMode ? "bg-gray-900 text-white" : "bg-white text-black"
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="icon"
              className={`md:hidden ${
                isDarkMode
                  ? "text-white hover:bg-gray-700"
                  : "text-black hover:bg-gray-300"
              }`}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </Button>
            <h1
              className="text-xl font-bold flex items-center gap-2 cursor-pointer"
              onClick={() => navigate("/")}
            >
              {t("dashboard_title")}
              <Logo size={24} />
            </h1>
          </div>
          {isMobile ? null : (
            <Tooltip delayDuration={0}>
              <div className="flex items-center ml-auto">
                <a
                  onClick={toggleTheme}
                  className={`flex items-center p-2 rounded-lg transition-colors text-sm font-medium ${
                    isDarkMode
                      ? "text-white hover:cursor-pointer"
                      : "text-black hover:cursor-pointer"
                  }`}
                >
                  <TooltipTrigger>
                    {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("theme_tooltip")}</p>
                  </TooltipContent>
                </a>

                <Tooltip delayDuration={0}>
                  <a
                    href="https://help.guestsuite.app"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`flex items-center w-fit p-2 rounded-lg transition-colors text-sm font-medium ${
                      isDarkMode
                        ? "text-white hover:cursor-pointer"
                        : "text-black hover:cursor-pointer"
                    }`}
                  >
                    <TooltipTrigger>
                      <HelpCircle size={20} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("help_tooltip")}</p>
                    </TooltipContent>
                  </a>
                </Tooltip>

                <LanguageSelector />

                <div className="h-6 w-px bg-gray-300 mx-2" />
              </div>
            </Tooltip>
          )}

          <div className="flex items-center gap-4 pl-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="w-8 h-8 md:w-10 md:h-10 cursor-pointer">
                  <AvatarImage
                    className="object-contain"
                    src={user?.avatar_url}
                  />
                  <AvatarFallback className="bg-primary text-primary-foreground text-lg font-medium uppercase">
                    {user?.first_name?.[0]}
                    {user?.last_name?.[0]}
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-64">
                <div className="flex items-center gap-2 px-2 py-1.5">
                  <Avatar className="w-8 h-8">
                    <AvatarImage
                      className="object-contain"
                      src={user?.avatar_url}
                    />
                    <AvatarFallback>
                      {user?.first_name?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="space-y-0.5">
                    <p className="text-sm font-medium truncate">
                      {user?.first_name} {user?.last_name}
                    </p>
                    <p className="text-xs text-gray-500 truncate">
                      {user?.email}
                    </p>
                  </div>
                </div>
                <DropdownMenuSeparator className="my-1 border-t border-gray-300" />
                <DropdownMenuItem
                  className={`hover:bg-gray-100 flex items-center gap-2 ${isDarkMode ? "text-white" : "text-black"}`}
                  onClick={() => navigate("/dashboard/account")}
                >
                  <BadgeCheck size={16} />
                  {t("account_settings")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`hover:bg-gray-100 flex items-center gap-2 ${isDarkMode ? "text-white" : "text-black"}`}
                  onClick={() => navigate("/dashboard/billing")}
                >
                  <CreditCard size={16} />
                  {t("billing")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`hover:bg-gray-100 flex items-center gap-2 ${isDarkMode ? "text-white" : "text-black"}`}
                  onClick={() => navigate("/dashboard/notifications")}
                >
                  <Bell size={16} />
                  {t("notifications")}
                </DropdownMenuItem>
                <DropdownMenuSeparator className="my-1 border-t border-gray-300" />
                <DropdownMenuItem
                  className={`hover:bg-gray-100 flex items-center gap-2 ${isDarkMode ? "text-white" : "text-black"}`}
                  onClick={handleLogout}
                >
                  <LogOut size={16} />
                  {t("logout")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </header>

      {/* Content Area */}
      <div className="flex flex-1 min-h-0">
        {/* Sidebar mobile */}
        <div
          className={cn(
            "fixed inset-0 z-50 bg-black/80 backdrop-blur-sm transition-opacity md:hidden",
            isMobileMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none",
          )}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <div
            className={cn(
              "fixed inset-y-0 left-0 transition-transform duration-300",
              isMobileMenuOpen ? "translate-x-0" : "-translate-x-full",
              "bg-gray-800",
              "sm:max-w-xs max-w-sm w-64",
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <SidebarComponent
              activeSection={getActiveSection()}
              setActiveSection={(section) => {
                if (accessibleSections[section]) {
                  navigate(
                    section === "home" ? "/dashboard" : `/dashboard/${section}`,
                  );
                  if (isMobileMenuOpen) setIsMobileMenuOpen(false);
                }
              }}
              isAdmin={user?.role === "admin"}
              className="h-full"
              accessibleSections={accessibleSections}
              hasApartments={apartments.length > 0}
            />
          </div>
        </div>

        {/* Sidebar desktop */}
        <div className="hidden md:block h-full bg-gray-800">
          <SidebarComponent
            activeSection={getActiveSection()}
            setActiveSection={(section) => {
              if (accessibleSections[section]) {
                navigate(
                  section === "home" ? "/dashboard" : `/dashboard/${section}`,
                );
                if (isMobileMenuOpen) setIsMobileMenuOpen(false);
              }
            }}
            isAdmin={user?.role === "admin"}
            className="h-full"
            accessibleSections={accessibleSections}
            hasApartments={apartments.length > 0}
          />
        </div>

        {/* Main content */}
        <main className="flex-1 overflow-auto p-4 md:p-8 bg-gray-100 min-h-0">
          {apiError && (
            <p className="text-red-500 text-center">
              {t("error_message")}: {apiError}
            </p>
          )}
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
