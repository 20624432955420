// components/dashboard/ApartmentList.js
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemList from "./ItemList";
import AddApartment from "./AddApartment";

const ApartmentList = ({ apartments, isLoading, error, onRefetch }) => {
  const { t } = useTranslation();
  const [showAddForm, setShowAddForm] = useState(apartments.length === 0);

  const handleAddComplete = () => {
    setShowAddForm(false);
    onRefetch();
  };

  if (!isLoading && apartments.length === 0 && !error && showAddForm) {
    return (
      <div className="w-full h-full">
        <AddApartment onComplete={handleAddComplete} />
      </div>
    );
  }

  return (
    <ItemList
      items={apartments}
      isLoading={isLoading}
      error={error}
      onRefetch={onRefetch}
      itemType="apartments"
      title={t("apartments_title")}
      addNewLabel={t("apartments_add_new")}
      onAddNew={() => setShowAddForm(true)}
      customEmptyState={
        <div className="text-center py-8">
          <h3 className="text-xl font-semibold mb-2">
            {t("no_apartments_title") || "No Apartments Yet"}
          </h3>
          <p className="text-gray-500 mb-4">
            {t("no_apartments_message") ||
              "Start by adding your first apartment"}
          </p>
          <button
            onClick={() => setShowAddForm(true)}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            {t("add_first_apartment") || "Add First Apartment"}
          </button>
        </div>
      }
    />
  );
};

export default ApartmentList;
