import React from "react";
import {
  CheckCircle,
  KeyRound,
  Scan,
  ClipboardCheck,
  Smartphone,
  Lock,
  Shield,
  Receipt,
} from "lucide-react";
import { Card, CardContent } from "../../components/ui/card";

const FeaturesSection = () => {
  return (
    <section id="features" className="py-20 bg-white scroll-mt-20">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Funzionalità Complete per la Tua Struttura
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Automatizza le operazioni quotidiane e offri un'esperienza
            eccezionale ai tuoi ospiti
          </p>
        </div>

        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            {/* Colonna Automatizza */}
            <div>
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-8 flex items-center gap-2">
                <span className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center text-primary">
                  1
                </span>
                Automatizza
              </h2>
              <div className="space-y-6">
                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <CheckCircle className="h-5 w-5 text-primary" />
                      Check-in Online
                    </h3>
                    <p className="text-gray-600">
                      Permetti ai tuoi ospiti di effettuare il check-in prima
                      dell'arrivo, riducendo i tempi di attesa e migliorando
                      l'esperienza complessiva.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <KeyRound className="h-5 w-5 text-primary" />
                      Onsite Check-in
                    </h3>
                    <p className="text-gray-600">
                      Gestisci gli arrivi in struttura in modo efficiente con un
                      sistema di check-in rapido e intuitivo per te e il tuo
                      staff.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Scan className="h-5 w-5 text-primary" />
                      Verifica dell'identità
                    </h3>
                    <p className="text-gray-600">
                      Scansione e verifica automatica dei documenti di identità,
                      con riconoscimento OCR e archiviazione sicura conforme al
                      GDPR.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <ClipboardCheck className="h-5 w-5 text-primary" />
                      Registrazione Alloggiati Web
                    </h3>
                    <p className="text-gray-600">
                      Invio automatico dei dati degli ospiti alla Polizia di
                      Stato tramite il portale Alloggiati Web, in conformità con
                      la normativa italiana.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Smartphone className="h-5 w-5 text-primary" />
                      Self Check-in
                    </h3>
                    <p className="text-gray-600">
                      Offri ai tuoi ospiti la possibilità di effettuare il
                      check-in autonomamente 24/7, senza la necessità della tua
                      presenza fisica.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Lock className="h-5 w-5 text-primary" />
                      Serrature Digitali
                    </h3>
                    <p className="text-gray-600">
                      Integrazione con serrature smart per generare codici di
                      accesso temporanei per ogni ospite, aumentando sicurezza e
                      comodità.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Shield className="h-5 w-5 text-primary" />
                      Assicurazioni e depositi
                    </h3>
                    <p className="text-gray-600">
                      Gestione automatica di cauzioni e assicurazioni per
                      proteggere la tua proprietà e offrire tranquillità ai tuoi
                      ospiti.
                    </p>
                  </CardContent>
                </Card>

                <Card className="border border-gray-100 hover:border-primary/20 hover:shadow-md transition-all">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2 flex items-center gap-2">
                      <Receipt className="h-5 w-5 text-primary" />
                      Tassa di soggiorno
                    </h3>
                    <p className="text-gray-600">
                      Calcolo e riscossione automatica dell'imposta di
                      soggiorno, con generazione di report per le autorità
                      locali.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>

            {/* Immagine illustrativa */}
            <div className="flex items-center justify-center">
              <div className="relative">
                <div className="absolute -inset-4 bg-primary/5 rounded-xl -z-10"></div>
                <img
                  src="/features-dashboard.png"
                  alt="Dashboard delle funzionalità"
                  className="rounded-lg shadow-lg border border-gray-200"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
